import { FaHeart, FaRegHeart, FaShoppingBasket, FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { getCentPart, getIntPart, enumToColorClass } from '../../utils/functions';
import './bagde-card.scss';
import HttpService from '../../services/HttpService';
import { BadgeData } from '../../models/statistics/badgeData.model';

type BagdeCardProps = {
  badge: BadgeData
}

function BagdeCard(props: BagdeCardProps) {


  return (
    // Change onclick
    <div className={`bagde-card ${props.badge.achieved ? '' : 'bagde-card--locked'}`}>
      <img className='bagde-card__image' src={`/assets/${props.badge.image}`} alt="badge">
      
      </img>
      <div className='bagde-card__image-title'>
      {props.badge.title}

        </div>
      <h2 className='bagde-card__title'>
        {props.badge.title}
      </h2>
      <p className='bagde-card__description'>
      {props.badge.description}
      </p>
      <h4 className='bagde-card__achieved'>
      {props.badge.achieved ? 'UNLOCKED' : 'LOCKED'}

      </h4>
    </div>
  );
}

export default BagdeCard;
