import './history.scss';
import { useViews } from '../../hooks/use-progress';
import { useState } from 'react';
import Chart from '../chart/chart';

function History() {
  const [nrOfDays, setNrOfDays] = useState(7);
  const { isLoading, data } = useViews(nrOfDays);

  return (
    <div>
      <h2>Watch history</h2>
      <Chart data={data} days={nrOfDays} setDays={setNrOfDays} isLoading={isLoading} />
    </div>
  );
}


export default History;
