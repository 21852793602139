import './chart.scss';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { useState, useEffect } from 'react';
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import structuredClone from '@ungap/structured-clone';
import { useTheme } from '../../hooks/use-theme';
import { ThemeType } from '../../enums/theme-type.enum';

const chartOptions: ApexOptions = {
  chart: {
    type: 'bar',
    height: 350,
    foreColor: '#373d3f',
    stacked: true,
    toolbar: {
      show: true
    },
    zoom: {
      enabled: false
    }
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0
      }
    }
  }],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 10
    },
  },
  xaxis: {
    type: 'datetime',
    categories: [],
  },
  legend: {
    position: 'right',
    offsetY: 40
  },
  fill: {
    opacity: 1
  }
};

function Chart(props: { data: any, days: number, setDays: any, isLoading: boolean }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState<ApexOptions>(chartOptions);
  const { theme } = useTheme();

  useEffect(() => {
    if (!props.data) return;
    const statistics = props.data.data;
    const newOptions = structuredClone(options);
    if (!newOptions.xaxis || !newOptions.chart) return;
    newOptions.xaxis.categories = [...statistics.dayList];
    setSeries(statistics.series);
    newOptions.chart.foreColor = (theme === ThemeType.LIGHT ? '#000000' : '#ffffff');
    setOptions(newOptions);
  }, [props.data])

  useEffect(() => {
    if (!options || !options.xaxis || options.xaxis.categories.length == 0) return;
    const newOptions = structuredClone(options);
    if (!newOptions.chart) return;
    newOptions.chart.foreColor = (theme === ThemeType.LIGHT ? '#000000' : '#ffffff');
    setOptions(newOptions);
  }, [theme])

  if (props.isLoading || !props.data) {
    return (<div className="loading">
      <CircularProgress color="secondary" />
    </div>)
  } else {
    return (
      <div>
        {series && options.xaxis && options.xaxis.categories.length > 0 ?
          <div>
            <ReactApexChart options={options} series={series} type="bar" height={350} />
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Timeframe</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                value={props.days}
                name="row-radio-buttons-group"
                onChange={(e) => { props.setDays(parseInt(e.target.value)) }}
              >
                <FormControlLabel value="7" control={<Radio />} label="7 Days" />
                <FormControlLabel value="30" control={<Radio />} label="30 Days" />
                <FormControlLabel value="60" control={<Radio />} label="60 Days" />
              </RadioGroup>
            </FormControl>
          </div>
          : <>
            <h3>No data yet. Please come back later...</h3>
          </>}

      </div>
    );
  }
}


export default Chart;
