import AuthService from './AuthService';
import HttpService from './HttpService.js';

const axios = HttpService.getAxiosClient();
const statisticsUrl = `${process.env.REACT_APP_STATISTICS_HOST}/api`;

const sendVideoView = async (videoId: string) => {
  if (!AuthService.isLoggedIn()) return null;
  axios.post(`${statisticsUrl}/statistics/views`, { videoId });
};

const getVideoViews = async (days = 7) => {
  if (!AuthService.isLoggedIn()) return null;
  return axios.get(`${statisticsUrl}/statistics/views?days=${days}`);
};
const getVideoViewsTeacher = async (days = 7) => {
  if (!AuthService.isLoggedIn()) return null;
  return axios.get(`${statisticsUrl}/statistics/teacher/views?days=${days}`);
};
const getPurchases = async (days = 7) => {
  if (!AuthService.isLoggedIn()) return null;
  return axios.get(`${statisticsUrl}/statistics/teacher/purchases?days=${days}`);
};
const getProgress = async () => {
  if (!AuthService.isLoggedIn()) return null;
  return axios.get(`${statisticsUrl}/statistics/progress`);
};

const StatisticsService = {
  sendVideoView,
  getVideoViews,
  getVideoViewsTeacher,
  getProgress,
  getPurchases
};

export default StatisticsService;
