import { ActionCardAlign } from "../../enums/actio-card-align.enum";
import { InteractiveCardType } from "../../enums/interactive-card-type";
import { InteractiveCard } from "../../interfaces/interactive-card.interface";

export class CourseCard implements InteractiveCard {
  // metadata
  id: number = 0;
  dx: number = 0;
  dy: number = 0;
  dxRel: number = 0;
  dyRel: number = 0;
  dxTemp: number = 0;
  dyTemp: number = 0;
  type: InteractiveCardType = InteractiveCardType.COURSE;
  showTime: number = 0;
  hideTime: number = 0;
  pauseVideo: boolean = true;
  hasTimer: boolean = false;
  // specific metadata
  duration: number = 3; // seconds
  showCaption: boolean = false;
  imageWidth:number = 30; // percentage
  imageHeigth:number = 30;
  
  // content
  title: string = 'Beautiful course title';
  courseId: string = '123';
  courseLen: number = 360; // in minutes
  courseLenFormatted: string = '6 hours';
  imageUrl: string = 'https://images.theconversation.com/files/443350/original/file-20220131-15-1ndq1m6.jpg?ixlib=rb-1.1.0&rect=0,0,3354,2464&q=45&auto=format&w=926&fit=clip';

  // style
  cardColor: string = 'black';

  constructor(data: any) {
    Object.assign(this, data);
    if(this.id === 0) {
      this.id = Math.floor(Math.random() * 1000000);
    }  
    this.courseLenFormatted = this.minutesFormatted(this.courseLen);
  }

    minutesFormatted(minutes: number) {
      return `${Math.floor(minutes/60)} hours ${minutes%60} mins`; 
    }
}

export { }