import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './filters.scss';
import CheckboxTree from 'react-checkbox-tree';
import { FaCheckSquare } from 'react-icons/fa';
import { ImCheckboxUnchecked } from 'react-icons/im';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { VscExpandAll, VscCollapseAll } from 'react-icons/vsc';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { useTheme } from '../../hooks/use-theme';
import { ThemeType } from '../../enums/theme-type.enum';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { Checkbox, FormControlLabel } from '@mui/material';

class FilterProps {
  priceInterval: number[] = [0, 999];
  rateInterval: number[] = [0, 5];
  setRateInterval: any;
  setPriceInterval: any;
}

function Filters(props: FilterProps) {
  const [checked, setChecked] = useState<string[]>([]);
  const [hidden, setHidden] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const { theme } = useTheme();
  const [checkedType, setCheckedType] = React.useState([{ name: 'Video course', checked: true }, { name: 'Article', checked: false }]);
  const { priceInterval, setPriceInterval, rateInterval, setRateInterval } = props;
  const priceMarks = [
    {
      value: 10,
      label: '$10',
    },
    {
      value: 100,
      label: '$100',
    },
    {
      value: 207,
      label: '$207',
    },
    {
      value: 410,
      label: '$410',
    },
  ];

  const ratingMarks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    }
  ];

  const nodes = [{
    value: 'all',
    label: 'All',
    children: [{ // ezeket csak adatbazisbol vagy admin use profilbol lehessen modositani
      value: 'sec',
      label: 'Security'
    },
    {
      value: 'web',
      label: 'Web',
      children: [
        {
          value: 'fe',
          label: 'Frontend',
          children: [
            { value: 'react', label: 'react' },
            { value: 'vue', label: 'vue' },
          ]
        },
        { value: 'be', label: 'Backend' },
      ],
    }, {
      value: 'ops',
      label: 'DevOps',
      children: [
        { value: 'cloud', label: 'Cloud' },
        { value: 'ks', label: 'Kubernetes' },
      ],
    }]
  }
  ];

  useEffect(() => {
    console.log("checked changed", checked);

  }, [checked])


  const handlePriceChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    const minDistance = 1;
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceInterval([Math.min(newValue[0], priceInterval[1] - minDistance), priceInterval[1]]);
    } else {
      setPriceInterval([priceInterval[0], Math.max(newValue[1], priceInterval[0] + minDistance)]);
    }
  }

  const handleRateChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    const minDistance = 0.1;
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRateInterval([Math.min(newValue[0], rateInterval[1] - minDistance), rateInterval[1]]);
    } else {
      setRateInterval([rateInterval[0], Math.max(newValue[1], rateInterval[0] + minDistance)]);
    }
  }

  const toggleFilters = () => {
    setHidden(!hidden);
  }

  const typeCheckChange = (index: number) => (e: any) => {
    // event.target.checked
    //  (index: number) => {
    let types = checkedType.slice();
    types[index].checked = !types[index].checked;

    setCheckedType(types);
    // setCheckedType();
    console.log("typeCheckChange ", index, types, checkedType);


  }

  /*
  ${hidden ? 'filters--shrinked' : 'filters--expanded'}
  */
  return (
    <div className={`filters ${theme === ThemeType.LIGHT ? 'filters--light' : 'filters--dark'}
     `}
      style={{
        transform: `${hidden
          ? 'translateX(0%)'
          : 'translateX(100%)'}`,
        transition: 'transform 0.5s ease-in-out'
      }}>
      {/* <div style={{position: 'relative'}}> */}
      <div className='filters__exp-btn' onClick={toggleFilters}>
      {/* <span className='filters__exp-btn__mobile-hide'>{"<< Hide "}</span>Filters */}
      <span className='filters__exp-btn__mobile-hide'>{"  Hide  "}</span>Filters
      </div>
      Category
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={checked => setChecked(checked)}
        onExpand={expanded => setExpanded(expanded)}

        // iconsClass="fa5"
        //   icons={{
        //     check: <span className="rct-icon rct-icon-check" />,
        //     uncheck: <span className="rct-icon rct-icon-uncheck" />,
        //     halfCheck: <span className="rct-icon rct-icon-half-check" />,
        //     expandClose: <span className="rct-icon rct-icon-expand-close" />,
        //     expandOpen: <span className="rct-icon rct-icon-expand-open" />,
        //     expandAll: <span className="rct-icon rct-icon-expand-all" />,
        //     collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
        //     parentClose: <span className="rct-icon rct-icon-parent-close" />,
        //     parentOpen: <span className="rct-icon rct-icon-parent-open" />,
        //     leaf: <span className="rct-icon rct-icon-leaf" />,
        // }}
        icons={{
          // check: <FaCheckSquare />,
          // uncheck: <ImCheckboxUnchecked />,
          // halfCheck: <FaCheckSquare />,
          // expandClose: <RiArrowDropDownLine />,
          // expandOpen: <RiArrowDropUpLine />,
          expandClose: <IoIosArrowDown />,
          // expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon="chevron-right" />,
          expandOpen: <IoIosArrowUp />,
          expandAll: <VscExpandAll />,
          collapseAll: <VscCollapseAll />,
          // parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon="folder" />,
          // parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon="folder-open" />,
          // leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon="file" />
        }}
        showNodeIcon={false}
        showExpandAll

      />
      {/* <Box sx={{ width: 300, margin: 10}}> */}
      <div className='price-filter slider-filter'>
        Price
        <Slider
          getAriaLabel={() => 'Minimum distance'}
          value={priceInterval}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          // getAriaValueText={() => 'Blaaa'}
          // disableSwap
          min={10}
          max={410}
          marks={priceMarks}
          // aria-label="Temperature"
        // color="secondary"
        />
      </div>
      <div className='rating-filter slider-filter'>
        Rating
        <Slider
          getAriaLabel={() => 'Minimum distance'}
          value={rateInterval}
          onChange={handleRateChange}
          valueLabelDisplay="auto"
          // getAriaValueText={() => 'Blaaa'}
          // disableSwap
          min={0}
          max={5}
          step={0.1}
          marks={ratingMarks}
          // aria-label="Rating"
        // color="secondary"
        />
      </div>

      Type
      {/* some kind of checkbox */}

      {
        checkedType.map((type, i) => (
          <FormControlLabel
            key={type.name}
            label={type.name}
            control={
              <Checkbox checked={type.checked}
                onChange={typeCheckChange(i)}
              />}
          />
        ))
      }

      {/*Order:  ezt lehet inkabb a content fel kene --> drop-down VAGY itt radiobutton-es*/}

    </div>
  );
}

export default Filters;
