import { useEffect } from "react";
import React from 'react';
import './CourseWatching.scss';
import { VideoJS } from '../../components/video-js/video-js';
import { useCourse } from '../../hooks/use-course';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Box, Stack, Tab, Paper } from '@mui/material';
import { useTheme } from '../../hooks/use-theme';
import { ThemeType } from '../../enums/theme-type.enum';
import { VideoJSUseMode } from '../../enums/videojs-use-mode.enum';
import { useInteractiveVideo } from '../../hooks/use-interactive-video';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { colors, padding } from '../../utils/constants';
import Reviews from "../reviews/reviews";
import QA from "../qa/qa";
import HttpService from "../../services/HttpService";
import StatisticsService from "../../services/StatisticsService";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";


const videoJsOptions = {
  autoplay: true,
  controls: true,
  responsive: true,
  preload: "auto",
  fluid: true,
  sources: [{
    src: '/do_I_wanna.mp4',
    type: 'video/mp4'
    // src: "https://storage.googleapis.com/coursebook-videos-2/processed/8-190828_27_SuperTrees_HD_17.mp4/master.m3u8",
    // type: "application/x-mpegURL"
  }],
  plugins: [
  ]
}

const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;
const axios = HttpService.getAxiosClient();

function CourseWatching() {
  const playerRef = React.useRef(null);
  const { id } = useParams();
  const courseData = useCourse(id ?? '1'); // {isLoading, data}
  const [tabIndex, setTabIndex] = React.useState('1');
  const [videoOptions, setVideoOptions] = React.useState(videoJsOptions);
  const [actualVideoIndex, setActualVideoIndex] = React.useState(-1);
  const interactiveVideoData = useInteractiveVideo(id, parseInt(courseData?.data?.videos[actualVideoIndex]?.id)); // {isLoading, data}
  const { theme } = useTheme();

  useEffect(() => {
    if (actualVideoIndex == -1) {
      const intro = courseData.data.videos.findIndex(v => parseInt(v.id) === courseData.data.introVideo);
      if (intro != -1) {
        setActualVideoIndex(intro)
        // src: intro.streamLink,
        // type: 'application/x-mpegURL'
      }
    }
  }, [courseData]);

  useEffect(() => {
    console.log(111);
    
   console.log("interactivities", interactiveVideoData, id, actualVideoIndex, courseData?.data?.videos[actualVideoIndex]);
   
  }, []);

  const redirectToCheckout = async () => {
    try {
      const body = await axios.post(`${baseApiUrl}/checkout`, { courseId: id });
      window.location.href = body.data.url;
    } catch (error) {
      console.log("Unauthorized to purchase");
      return null;
    }
  }

  const handleTabChange = (event: any, newValue: any) => {
    setTabIndex(newValue);
  };

  const handleOpenVideo = (index: number) => {
    // get video details - like interactivities - if interative
    setActualVideoIndex(index);
    // send post to backend
    StatisticsService.sendVideoView(courseData.data.videos[index].id);
    

    // navigate to video slug
  };

  const Item = styled(Paper)(() => ({
    backgroundColor: theme === ThemeType.DARK ? colors['grey-1'] : colors['grey-5'],
    padding: padding['padding-xxs'],
    textAlign: 'center',
    color: colors['pink'],
    fontSize: '1.8rem'
  }));


  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };
  if (courseData.isLoading) {
    return (
      <div>
        Loading...
      </div>
    )
  } else {

    return (
      <div className={`course-watching ${theme === ThemeType.LIGHT ? 'course-watching--light' : 'course-watching--dark'}`}>
        {/* {console.log("interactiveVideoData", interactiveVideoData?.data)} */}
        <div className={`course-watching__tv ${theme === ThemeType.LIGHT ? 'course-watching__tv--light' : 'course-watching__tv--dark'}`}>
          <VideoJS
            options={videoOptions}
            onReady={handlePlayerReady}
            videoList={courseData?.data.videos ?? []}
            videoJSUseMode={VideoJSUseMode.WATCH}
            actualVideoIndex={actualVideoIndex}
            interactiveCards={interactiveVideoData?.data}
            handleOpenVideo={handleOpenVideo}
          />
        </div>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Course Overview" value="1" />
                <Tab label="Video Overview" value="2" />
                <Tab label="Q&A" value="3" />
                <Tab label="Additional Material" value="4" />
                <Tab label="Reviews" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <h2>{courseData?.data.title}</h2>
              <div>Category: {courseData?.data.category}</div>
              <div>Enrolled students: {courseData?.data.studentNr}</div>
              <div>Rating: {courseData?.data.rating}</div>
              
              {!courseData?.data.purchased &&
                <Button variant="contained" onClick={redirectToCheckout}>{`Purchase for ${courseData?.data.price}$`}</Button>
              }
              <div>
                <h3>Description:</h3> 
                 {/* <p>{courseData?.data.description}</p> */}
                 <ReactMarkdown children={courseData?.data.description} remarkPlugins={[remarkGfm]} />
                 </div>
              <div>
                <h3>Videos:</h3>
                <Stack spacing={2}>
                  {courseData?.data.videos.map((video, index) => {
                    if (courseData.data.purchased || video.public) {
                      return <Item className='video-list-element' key={video.id}
                        onClick={() => handleOpenVideo(index)}>
                        {video.position + 1}. {video.title}
                      </Item>
                    }
                    return <Item className={`${theme === ThemeType.LIGHT ? 'video-list-element-private--light' : 'video-list-element-private--dark'}`} key={video.id}>{video.position + 1}. {video.title}</Item>
                  }
                  )}
                </Stack>
              </div>
            </TabPanel>
            <TabPanel value="2">

              {courseData && courseData.data.videos && actualVideoIndex != -1 &&
                <div>
                  <h2>{courseData.data.videos[actualVideoIndex].position + 1}. {courseData.data.videos[actualVideoIndex].title}</h2>
                  {courseData?.data.videos[actualVideoIndex].description &&
                    <div>
                      <h3>Description:</h3>
                      {/* <p>{courseData?.data.videos[actualVideoIndex].description}</p> */}
                      <ReactMarkdown children={courseData?.data.videos[actualVideoIndex].description} remarkPlugins={[remarkGfm]} />

                    </div>
                  }
                  <div className='video-navigation'>
                    <div className='video-navigation__prev'>
                      {actualVideoIndex > 0 &&
                        <Button variant="contained" onClick={() => handleOpenVideo(actualVideoIndex - 1)}>Previous</Button>
                      }
                    </div>
                    <div className='video-navigation__next'>
                      {actualVideoIndex < courseData.data.videos.length - 1 &&
                        <Button variant="contained" onClick={() => handleOpenVideo(actualVideoIndex + 1)}>Next</Button>
                      }
                    </div>

                  </div>
                </div>
              }

            </TabPanel>
            <TabPanel value="3">
              <QA />
            </TabPanel>
            <TabPanel value="4">Item Progress

            </TabPanel>
            <TabPanel value="5">
              <Reviews />
            </TabPanel>

          </TabContext>
        </Box>

      </div >
    );
  }
}

export default CourseWatching;
