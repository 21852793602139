import { useQuery, useMutation, useQueryClient } from "react-query";
import { CreateCourseData } from "../models/course-data/createCourseData.model.js";
import AuthService from '../services/AuthService';
import HttpService from '../services/HttpService.js';
const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

export const useCourses = (filter: string) => {
  return useQuery(['courses', filter, AuthService.isLoggedIn()], () => {
    const resp = axios.get(`${process.env.REACT_APP_HOST}/api/courses${filter ? ('?' + filter) : ''}`);
    return resp;
  });
}

export const useFavouriteCourses = () => {
  return useQuery(['courses', 'favourites'], () => {
    const resp = axios.get(`${process.env.REACT_APP_HOST}/api/courses/favourites`);
    return resp;
  });
}

export const useBestCourses = () => {
  return useQuery(['courses', 'best'], () => {
    const resp = axios.get(`${process.env.REACT_APP_HOST}/api/courses/best`);
    return resp;
  });
}

// TODO
export const usePurchasedCourses = () => {
  return useQuery(['courses', 'purchased'], () => {
    const resp = axios.get(`${process.env.REACT_APP_HOST}/api/courses/purchased`);
    return resp;
  });
}

const createCourse = async (course: CreateCourseData) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses`, course);
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const useCreateCourse = () => {
  return useMutation(createCourse);
}