import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import logo from './logo.svg';
import './picture-component.scss';


function PictureComponent
  () {
    // const url = 'https://i.pinimg.com/originals/f0/52/ee/f052ee1a651e8b57aa88e06e38bbcd22.gif'
const url = 'https://i.pinimg.com/originals/f0/52/ee/f052ee1a651e8b57aa88e06e38bbcd22.gif'
  return (
    <div className="picture-component">
      {/* <img src='https://miro.medium.com/max/1400/1*1HiMZ1JFtQ2vaXL9z8dkDg.jpeg'></img> */}
    <img src={url} alt="img" />
    </div>

  );
}

export default PictureComponent;
