import React, { useRef, useState, useEffect } from "react";
import { HiMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Button from "../button/button";
import DraggableComponent from "../draggable-component/draggable-component";
import './video-js.scss';
import 'videojs-markers';
import { InteractiveCard } from "../../interfaces/interactive-card.interface";
import { MenuItem, Select, TextField } from "@mui/material";
import QuestionSessionForm from "../question-session-form/question-session-form";
import { InteractiveCardType } from "../../enums/interactive-card-type";
import { ActionCard, ActionCardContent, ActionCardContentType } from "../../models/interactive-video/action-card.model";
import { VideoJSUseMode } from "../../enums/videojs-use-mode.enum";
import { InteractiveVideoData } from "../../models/interactive-video/interactive-video.model";
import { CourseVideoData } from "../../models/course-data/courseData.model";
import ActionComponent from "../action-component/action-component";
import PictureComponent from "../picture-component/picture-component";
import CourseNavigateComponent from "../course-navigate-component/course-navigate-component";
import { CourseCard } from "../../models/interactive-video/course-card.model";
import { QuestionCard } from "../../models/interactive-video/question-card.model";
import { PictureCard } from "../../models/interactive-video/picture-card.model";

import qualitySelector from 'videojs-hls-quality-selector';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import { Color, ColorPicker } from "material-ui-color";
import { Answer, Question } from "../../models/interactive-video/question-base.model";

const streamSource = {
  src: '',
  type: "application/x-mpegURL"
}

export const VideoJS = (props: {
  options: any;
  onReady: any;
  handleOpenVideo?: any;
  videoList: CourseVideoData[];
  actualVideoIndex: number;
  videoJSUseMode: VideoJSUseMode;
  interactiveCards?: InteractiveCard[];
  setParentInteractiveCards?: any;
  setParentNewInteractiveCards?: any;
}) => {

  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const playerRef = useRef<any>(null);
  const { options, onReady, videoList, actualVideoIndex, setParentInteractiveCards, setParentNewInteractiveCards } = props;
  const [menuIsVisible, setMenuIsVisible] = useState<boolean>(false);
  const [editPanelVisible, setEditPanelVisible] = useState<boolean>(false);
  const [draggableIsVisible, setDraggableIsVisible] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  // All the interactivities that appear in the video
  const [interactiveCards, setInteractiveCards] = useState<InteractiveCard[]>([]);
  const [newInteractiveCards, setNewInteractiveCards] = useState<InteractiveCard[]>([]);
  // Interactivities that appear in the current scene
  const [currentInteractiveCards, setCurrentInteractiveCards] = useState<InteractiveCard[]>([]);
  const [selectedCard, setSelectedCard] = useState<InteractiveCard | undefined>();
  const [currentSecond, setCurrentSecond] = useState<number>(0);
  // interactivity card add new content component
  const [newActionCardContent, setNewActionCardContent] = useState<ActionCardContent>(new ActionCardContent());
  const [newQuestion, setNewQuestion] = useState<Question>(new Question());
  const [newAnswers, setnewAnswers] = useState<Answer[]>([]);
  const [oldSelectedCardId, setOldSelectedCardId] = useState(0);

  useEffect(() => {
    if (props.interactiveCards) {
      setInteractiveCards(props.interactiveCards);
    }
  }, [props.interactiveCards]);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current && interactiveCards !== undefined) {
      const videoElement = videoRef.current;
      // const fullScreenButton = (ReactDOM.findDOMNode(videoElement) as Element).getElementsByClassName('vjs-fullscreen-control') 

      if (!videoElement) return;
      videojs.registerPlugin('hlsQualitySelector', qualitySelector)

      const player = playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        player.hlsQualitySelector({ displayCurrentQuality: true });

        player.qualityLevels();
        onReady && onReady(player);
      });

      const markers = interactiveCards.map(interactiveCard => { return { time: interactiveCard.showTime, text: 'kinga' } });
      //@ts-ignore
      player.markers({
        markerStyle: {
          'width': '4px',
          'background-color': 'pink'
        },
        markers
      });

      player.on('timeupdate', function () {
        // setCurrentSecond ==> on update - show card/s
        setCurrentSecond(Math.round(player.currentTime()));
        // console.log('player.currentTime():',player.currentTime());
      })

      player.on('pause', function () {
        setDraggableIsVisible(true);
        // console.log("time", player.currentTime());
        // currentTime(seconds) --> set current time: when clicking on one listed card
        // myPlayer.duration();


        // Modals are temporary by default. They dispose themselves when they are
        // closed; so, we can create a new one each time the player is paused and
        // not worry about leaving extra nodes hanging around.
        // var modal = player.addChild(
        // <div className="draggable-container">
        //   {interactiveCards.map((card: any) => (
        //     <DraggableComponent>
        //       {card.id}
        //     </DraggableComponent>
        //   ))}
        // </div>);
        // var modal = player.createModal(
        //   <div className="draggable-container">
        //     {interactiveCards.map((card: any) => (
        //       <DraggableComponent>
        //         {card.id}
        //       </DraggableComponent>
        //     ))}
        //   </div>, {});

        // When the modal closes, resume playback.
        // modal.on('modalclose', function () {
        //   player.play();
        // });
        let vid = document.getElementById("foo");
        //@ts-ignore
        const vw = vid?.videoHeight; // returns the intrinsic height of the video
        //@ts-ignore
        const hw = vid?.videoWidth; // returns the intrinsic width of the video
        console.log("w, h", vw, hw, player.videoHeight(), player.videoWidth);
      });
    } else if (!!playerRef.current) {
      // console.log("ELSE: playerRef.current has value ", playerRef.current);
      // playerRef.current.src= options.sources[0].src;
      // // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources[0]);
      // const x = props.interactivities.interactivities.map(interactiveCard => { return { time: interactiveCard.showTime, text: 'kinga' } });
      const markers = interactiveCards.map(interactiveCard => { return { time: interactiveCard.showTime, text: 'kinga' } });
      playerRef.current.markers.reset(markers);
      // //@ts-ignore

      // console.log("markers", player.hasPlugin('markers'), x);
      // //@ts-ignore
      // player.markers({
      //   markerStyle: {
      //     'width': '4px',
      //     'background-color': 'pink'
      //   },
      //   markers: x
      // });

      // player.on('timeupdate', function () {
      //   setCurrentSecond(Math.round(player.currentTime()));
      // })

    }
  }, [options, videoRef, containerRef, interactiveCards]);

  useEffect(() => {
    if (actualVideoIndex != -1) {
      const actualVideo = videoList[actualVideoIndex];
      streamSource.src = actualVideo.streamLink;
      playerRef.current.src([streamSource]);
    }

  }, [actualVideoIndex]);

  useEffect(() => {
    if (!interactiveCards) return;
    const currentCards = interactiveCards
      .filter(card => card.showTime <= currentSecond && (card.hideTime >= currentSecond || card.showTime + card.duration >= currentSecond))

    // console.log("current second effect", currentSecond, actualInteractivities, currentCards);

    setCurrentInteractiveCards(currentCards);
    const shouldStop = currentCards.reduce(
      (prevValue, card2) => (prevValue || card2.pauseVideo), false)
    if (shouldStop) {
      console.log("Paused!!!!");
      playerRef.current.pause();
    }
  }, [currentSecond, interactiveCards])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        console.log("PLAYER DISPOSING")
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    console.log("Fullscreen changed: ", isFullScreen, "w,h:", playerRef?.current?.videoWidth, playerRef?.current?.videoHeight);

  }, [isFullScreen]);

  useEffect(() => {
    // TODO: maybe this sould be deleted, cause I don't use it
    console.log("interactiveCards changed", interactiveCards);

  }, [interactiveCards]);

  useEffect(() => {
    console.log("selectedcard updated or changed", oldSelectedCardId, selectedCard, selectedCard?.id === oldSelectedCardId, selectedCard && selectedCard.id === oldSelectedCardId);
    if (selectedCard && selectedCard.id === oldSelectedCardId) {
      // card modified
      applySelectedCard();

    } else {
      // card changed
      setOldSelectedCardId(selectedCard?.id ?? 0);
      console.log("oldSelectedCardId", oldSelectedCardId);

    }

  }, [selectedCard]);

  const toggleMenu = () => {
    console.log("toggle menu", menuIsVisible);

    setMenuIsVisible(!menuIsVisible);
  }

  // TODO: set all types
  const addCard = (type: InteractiveCardType) => () => {
    console.log("clicked");
    // pause the video
    playerRef.current.pause();
    const currentTime = playerRef.current.currentTime();
    playerRef.current.play();
    const newCards = interactiveCards.slice(0);
    let newCard: InteractiveCard;
    switch (type) {
      case InteractiveCardType.ACTION:
        newCard = new ActionCard({});
        // newCards.push(new ActionCard({}))
        break;
      case InteractiveCardType.QUESTION:
        newCard = new QuestionCard({});
        // newCards.push(new QuestionCard({}))
        break;
      case InteractiveCardType.PICTURE:
        newCard = new PictureCard({});
        // newCards.push(new PictureCard())
        break;
      default:
        newCard = new CourseCard({});
        // newCards.push(new CourseCard({}))
        break;
    }
    newCard.showTime = currentTime;
    newCards.push(newCard);

    const newInteractiveCardsCopy = [...newInteractiveCards];
    newInteractiveCardsCopy.push(newCard);
    setNewInteractiveCards(newInteractiveCardsCopy);
    setInteractiveCards(newCards);

    if (setParentInteractiveCards) {
      setParentInteractiveCards(newCards);
    }

    if(setParentNewInteractiveCards) {
      setParentNewInteractiveCards(newInteractiveCardsCopy);
    }
  }

  const enterFullScreen = (event: any) => {
    console.log("Screen :", playerRef?.current?.videoWidth);

    if (document.fullscreenElement) {
      setIsFullScreen(false);

      return document.exitFullscreen();
    }
    if (containerRef.current == undefined) return;
    setIsFullScreen(true);
    return (containerRef.current as HTMLElement).requestFullscreen();
  }

  const closeDaggable = () => {
    if (editPanelVisible) {
      setEditPanelVisible(false);
    } else {
      addTempPosToStartPos();
      setDraggableIsVisible(false);
      playerRef.current.play();
    }
  }

  const clickDaggableComponent = (id: any) => () => {
    // id - card's id
    // console.log("clicked", id);
    if (editPanelVisible) {
      setEditPanelVisible(false);
      return;
    }
    const cards = interactiveCards.slice(0);
    const clickedCard = cards.filter(card => card.id === id)[0];
    setSelectedCard(clickedCard);
        // ha a selected card === question ==> settelni a new answereket
    if(clickedCard.type === InteractiveCardType.QUESTION) {
      console.log(1111);
      
      const newAns: Answer[] = [];
      console.log((clickedCard as QuestionCard).questions.length);
      
      (clickedCard as QuestionCard).questions.map(()=>{
        newAns.push(new Answer());
      })
      setnewAnswers(newAns);

    }
    setEditPanelVisible(true);
  }

  const addTempPosToStartPos = () => {
    // let cards = interactiveCards.slice(0); // copy by value, not referance
    let cards = [...interactiveCards]; // copy by value, not referance

    cards = cards.map(card => {
      card.dx += card.dxTemp;
      card.dy += card.dyTemp;
      return card;
    })
    console.log("addTempPosToStartPos", interactiveCards, cards);

    setInteractiveCards(cards);
    if (setParentInteractiveCards) {
      setParentInteractiveCards(cards);
    }
  }

  const cardDragged = (x: number, y: number, id: number): void => {
    console.log("cardx changed pos", id, x, y);
    // changeCard Temp pos
    let cards = interactiveCards.slice(0); // copy by value, not referance

    cards = cards.map(card => {
      if( card.id === id) {
        card.dxTemp = x;
        card.dyTemp = y;
      }
     
      return card;
    })

    setInteractiveCards(cards);
    if (setParentInteractiveCards) {
      setParentInteractiveCards(cards);
    }
  }

  const isInstanceOf = (instance: InteractiveCard, className: any) => {
    return instance.constructor.name === className.name
  }

  const setSelectedCardFieldChanged = (event: any) => {
    console.log("new card", { ...selectedCard, [event.target.name]: event.target.value });

    // @ts-ignore
    setSelectedCard({ ...selectedCard, [event.target.name]: event.target.value });
  }

  const stringToPath = (path: string) => {

    // If the path isn't a string, return it
    if (typeof path !== 'string') return path;

    // Create new array
    const output: string[] = [];

    // Split to an array with dot notation
    path.split('.').forEach(function (item, index) {

      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {

        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }

      });

    });

    return output;

  };

  const setSelectedCardFieldChangedByPath = (stringPath: string) => (event: any) => {
    let obj = Object.assign({}, selectedCard);
    const path = stringToPath(stringPath);

    console.log("setSelectedCardFieldChangedByPath", stringPath, event.target.value, path);

    const length = path.length;
    let current: any = obj;

    // Loop through the path
    path.forEach(function (key, index) {

      // If this is the last item in the loop, assign the value
      if (index === length - 1) {
        current[key] = event.target.value;
        console.log("setSelectedCardFieldChangedByPath got it", current, obj);
      }

      // Otherwise, update the current place in the object
      else {
        // If the key doesn't exist, create it
        if (!current[key]) {
          current[key] = {};
        }

        // Update the current place in the objet
        current = current[key];

      }

    });
    // @ts-ignore
    // setSelectedCard({ ...selectedCard, stringToPath(path): event.target.value });
    setSelectedCard(obj);
  }


  const setSelectedCardColorField = (name: string) => (newColor: Color) => {
    // console.log("change detected", field, event.target.value);


    // const card = new ActionCard({});
    // Object.assign(card, selectedCard);
    // console.log("type", card.constructor.name);

    // const type = selectedCard?.constructor || ActionCard;

    // /* @ts-ignore*/
    // card[`${field}`] = event.target.value;

    // console.log("card..", card);

    // setSelectedCard((card));

    // console.log("setcolor", [name], newColor.css.backgroundColor);


    // @ts-ignore
    setSelectedCard({ ...selectedCard, [name]: newColor.css.backgroundColor });
  }


  const newActionCardContentChanged = (event: any) => {
    console.log("new action card content", event.target.name, event.target.value);

    setNewActionCardContent({ ...newActionCardContent, [event.target.name]: event.target.value });
  }

  const addNewActionCardContentToSelectedItem = () => {
    // @ts-ignore
    const blocks = [...selectedCard.contentBlocks];
    blocks.push(newActionCardContent);

    // @ts-ignore
    setSelectedCard({ ...selectedCard, contentBlocks: blocks });
    setNewActionCardContent(new ActionCardContent());
  }
  
  const newQuestionChanged = (event: any) => {
    console.log("new setNewQuestion", event.target.name, event.target.value);

    setNewQuestion({ ...newQuestion, [event.target.name]: event.target.value });
  }

  const addNewQuestionToSelectedItem = () => {
    // @ts-ignore
    const questions = [...selectedCard.questions];
    // const question = new Question();
    // question.question = new
    questions.push(newQuestion);
    const newAns = [...newAnswers];
    newAns.push(new Answer());
    setnewAnswers(newAns);

    console.log("new question added: ", questions, {...selectedCard, questions: questions });
    

    // @ts-ignore
    setSelectedCard({...selectedCard, questions: questions });
    setNewQuestion(new Question);
  }

  const newAnswerChanged = (index: number) => (event: any) => {
    console.log("new newAnswerChanged", event.target.name, event.target.value, newAnswers);
    const answers = [...newAnswers];
    // @ts-ignore
    answers[index][`${event.target.name}`] = event.target.value;
    setnewAnswers(answers);
  }

  const addNewAnswerToSelectedItem = (index: number) => () => {
    // @ts-ignore
    const answers = [...selectedCard.questions[index].answers];
    // const question = new Question();
    // question.question = new
    answers.push({...newAnswers[index]});

    console.log("new answer added: ", answers);
    
    const selCard = {...selectedCard};

        // @ts-ignore
    selectedCard.questions[index].answers = answers;

    // @ts-ignore
    setSelectedCard(selCard);
    const answ = [...newAnswers];
    answ[index] = new Answer();
    setnewAnswers(answ);
  }

  const applySelectedCard = () => {
    // Set the card from original set to these values
    console.log("in apply...", interactiveCards);
    const cards = interactiveCards.map(card => {
      console.log("card is:", card.id, !!selectedCard);
      if (!!selectedCard && card.id === selectedCard.id) {
        console.log("got it:", selectedCard);

        return selectedCard;
      }
      return card;
    })
    setInteractiveCards(cards);
    if (setParentInteractiveCards) {
      setParentInteractiveCards(cards);
    }
  }

  const inputChanged = (field: any) => {
    // const card = selectedCard;
    // /* @ts-ignore*/
    // card[`${field}`] = value;
    // setSelectedCard(card);
  }

  return (<div className="video-js-component">
    {
      props.videoJSUseMode === VideoJSUseMode.EDIT && <div>
        <div >
          <Button onClick={addCard(InteractiveCardType.ACTION)} textConfig={[{ text: 'Add action' }]}></Button>
        </div>
        <div >
          <Button onClick={addCard(InteractiveCardType.QUESTION)} textConfig={[{ text: 'Add question' }]}></Button>
        </div>
        <div >
          <Button onClick={addCard(InteractiveCardType.PICTURE)} textConfig={[{ text: 'Add picture' }]}></Button>
        </div>
        <div >
          <Button onClick={addCard(InteractiveCardType.COURSE)} textConfig={[{ text: 'Add course' }]}></Button>
        </div>
      </div>
    }

    <div ref={containerRef} className={`video-js__container ${isFullScreen ? '' : 'video-js__container--not-fullscreen'}`}>
      <div data-vjs-player>
        <video id="foo" ref={videoRef} className="video-js vjs-big-play-centered" />
        {/* </div> */}
        {/* <div className="full-screen-overlay" onClick={enterFullScreen}></div> */}
        {currentInteractiveCards.length > 0 && <div className="vjs-modal-dialog draggable-container">
          <div style={{ position: 'relative' }}>
            {currentInteractiveCards.map((card: InteractiveCard) => (
              <DraggableComponent data={card} useMode={props.videoJSUseMode}
                onClick={clickDaggableComponent(card.id)}
                changedPos={cardDragged}>
                {/* {card.id} */}
                {
                  card.type === InteractiveCardType.ACTION && <ActionComponent actionCard={new ActionCard(card)}></ActionComponent>
                }
                {
                  card.type === InteractiveCardType.QUESTION && <QuestionSessionForm videoId={parseInt(props.videoList[props.actualVideoIndex]?.id)} useMode={props.videoJSUseMode} questionCard={new QuestionCard(card)} ></QuestionSessionForm>
                }
                {
                  card.type === InteractiveCardType.PICTURE && <PictureComponent></PictureComponent>
                }
                {
                  card.type === InteractiveCardType.COURSE && <CourseNavigateComponent courseCard={new CourseCard(card)} videoJsUseMode={props.videoJSUseMode}></CourseNavigateComponent>
                }
              </DraggableComponent>
            ))}
          </div>
        </div>
        }
      </div> {/* <-- ADDED NOW */}
      {/* {props.videoJSUseMode === VideoJSUseMode.WATCH
        &&
        <div style={{ position: 'relative' }}>
          {currentInteractiveCards.map(card =>
            (<DraggableComponent data={card} useMode={props.videoJSUseMode}
              onClick={clickDaggableComponent(card.id)}
              changedPos={cardDragged}>
                <QuestionSessionForm></QuestionSessionForm>
                </DraggableComponent>))}
        </div>} */}
      <div className="menu-box" style={{
        transform: `${menuIsVisible
          ? 'translateX(0%)'
          : 'translateX(100%)'}`,
        transition: 'transform 0.5s ease-in-out'
      }}>Video list
        {videoList && videoList.map((item: any, index: number) => (
          <div className="menu-box__elem" key={item.id} onClick={() => props.handleOpenVideo(index)}>
            {index + 1}. {item.title}
          </div>))
        }
      </div>
      <div className="edit-panel" style={{
        transform: `${editPanelVisible
          ? 'translateX(0%)'
          : 'translateX(100%)'}`,
        transition: 'transform 0.5s ease-in-out'
      }}>
        {/* Action Card section */}
        {
          selectedCard && (selectedCard.constructor.name === ActionCard.name
            || selectedCard.type === InteractiveCardType.ACTION)
          && selectedCard as ActionCard &&
          // true &&
          <>
            <div className="edit-panel__name">{selectedCard?.id}</div>
            <div className="edit-panel__section">
              <h3>Card metadata</h3>

              <ColorPicker value={(selectedCard as ActionCard).cardColor}

                onChange={setSelectedCardColorField('cardColor')} />
              <h3>Content</h3>

              {(selectedCard as ActionCard).contentBlocks.map((block, index) => <div className="edit-panel__section__elem">



                {/* {(selectedCard as ActionCard).text} */}
                {/* <TextField  size="small" label="Outlined" variant="outlined" /> */}
                {/* Text: <input type="text" name="name" value={block.content} onChange={setSelectedCardFieldChanged('text')} /> */}
                <TextField
                  className="input-elem"
                  id="outlined-textarea"
                  label="Title"
                  color="secondary"
                  placeholder="Title..."
                  name="content"
                  value={block.content}
                  onChange={setSelectedCardFieldChangedByPath(`contentBlocks.${index}.content`)}
                />
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={block.type}
                  label="type"
                  name="type"
                  onChange={setSelectedCardFieldChangedByPath(`contentBlocks.${index}.type`)}
                >
                  {Object.values(ActionCardContentType).filter(type => typeof type === 'string').map(type => <MenuItem value={type}>{type.toString()}</MenuItem>)}
                </Select>
              </div>)
              }
              <div>--------------------------</div>
              <div className="edit-panel__section__elem">
                <TextField
                  className="input-elem"
                  id="outlined-textarea"
                  label="Content Bock"
                  color="secondary"
                  placeholder="Content..."
                  name="content"
                  value={newActionCardContent.content}
                  onChange={newActionCardContentChanged}
                />
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newActionCardContent.type}
                  label="type"
                  name="type"
                  onChange={newActionCardContentChanged}
                >
                  {Object.values(ActionCardContentType).filter(type => typeof type === 'string').map(type => <MenuItem value={type}>{type.toString()}</MenuItem>)}
                </Select>
              </div>
              <div className="edit-panel__btn-wrapper">
                <Button textConfig={[{ text: 'Add new' }]} onClick={addNewActionCardContentToSelectedItem}></Button>

              </div>
            </div>
          </>
        }
        {
          selectedCard && (selectedCard.constructor.name === QuestionCard.name
            || selectedCard.type === InteractiveCardType.QUESTION)
          && selectedCard as QuestionCard &&
          // true &&
          <>
            <div className="edit-panel__name">{selectedCard?.id}</div>
            <div className="edit-panel__section">
              <h3>Card metadata</h3>

              <ColorPicker value={(selectedCard as QuestionCard).cardColor}

                onChange={setSelectedCardColorField('cardColor')} />
              <h3>Content</h3>

              {(selectedCard as QuestionCard).questions.map((question, index) =>
                <div className="edit-panel__section__elem">
                  {/* {(selectedCard as ActionCard).text} */}
                  {/* <TextField  size="small" label="Outlined" variant="outlined" /> */}
                  {/* Text: <input type="text" name="name" value={block.content} onChange={setSelectedCardFieldChanged('text')} /> */}
                  <TextField
                    className="input-elem"
                    id="outlined-textarea"
                    label="Question"
                    color="secondary"
                    placeholder="Question"
                    name="question"
                    value={question.question}
                    onChange={setSelectedCardFieldChangedByPath(`questions.${index}.question`)}
                  />

                  {question.answers.map((answer, answerIndex) =>
                    <div  className="edit-panel__section__elem">
                      <TextField
                        className="input-elem"
                        id="outlined-textarea"
                        label="Answer"
                        color="secondary"
                        placeholder="Answer..."
                        name="text"
                        value={answer.text}
                        onChange={setSelectedCardFieldChangedByPath(`questions.${index}.answers.${answerIndex}.text`)}
                      />
                      <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={answer.isCorrect}
                  label="Is Correct"
                  name="isCorrect"
                  onChange={setSelectedCardFieldChangedByPath(`questions.${index}.answers.${answerIndex}.isCorrect`)}
                >
                  {[true, false].map(type => <MenuItem value={type.toString()}>{type ? 'Correct' : 'Wrong'}</MenuItem>)}
                </Select>

                    </div>)}
                    <div className="edit-panel__section__elem">
                      <TextField
                        className="input-elem"
                        id="outlined-textarea"
                        label="Answer"
                        color="secondary"
                        placeholder="Answer..."
                        name="text"
                        value={newAnswers[index]?.text}
                        onChange={newAnswerChanged(index)}
                      /></div>
                   

                    <div className="edit-panel__btn-wrapper">
                <Button textConfig={[{ text: 'Add new Answer' }]} onClick={addNewAnswerToSelectedItem(index)}></Button>

              </div>

                  {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={question.type}
                  label="type"
                  name="type"
                  onChange={setSelectedCardFieldChangedByPath(`contentBlocks.${index}.type`)}
                >
                  {Object.values(ActionCardContentType).filter(type => typeof type === 'string')
                  .map(type => <MenuItem value={type}>{type.toString()}</MenuItem>)}
                </Select> */}
                </div>)
              }
              <div>--------------------------</div>
              <div className="edit-panel__section__elem">
                <TextField
                  className="input-elem"
                  id="outlined-textarea"
                  label="Content Bock"
                  color="secondary"
                  placeholder="Content..."
                  name="question"
                  value={newQuestion.question}
                  onChange={newQuestionChanged}
                />
                {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newActionCardContent.type}
                  label="type"
                  name="type"
                  onChange={newActionCardContentChanged}
                >
                  {Object.values(ActionCardContentType).filter(type => typeof type === 'string').map(type => <MenuItem value={type}>{type.toString()}</MenuItem>)}
                </Select> */}
              </div>
              <div className="edit-panel__btn-wrapper">
                <Button textConfig={[{ text: 'Add new' }]} onClick={addNewQuestionToSelectedItem}></Button>

              </div>
            </div>
          </>
        }
      </div>

      {props.videoJSUseMode === VideoJSUseMode.WATCH && <div className="menu-icon" onClick={toggleMenu}><HiMenu /></div>}
      {props.videoJSUseMode === VideoJSUseMode.EDIT && draggableIsVisible && <div className="menu-icon" onClick={closeDaggable}><IoClose /></div>}
    </div>
    {
      interactiveCards && props.videoJSUseMode === VideoJSUseMode.EDIT && interactiveCards.map(card =>
      (<div>
        {`${card.type} ${card.showTime}`}
      </div>))
    }
  </div>
  );
}

export default VideoJS;