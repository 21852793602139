import { AddEditFormType } from "../../enums/add-edit-form-type.enum";
import { CourseType } from "../../enums/course-type.enum";
import AddEditCourse from "../course-upload/add-edit-course/add-edit-course";
import './course-edit.scss';

function CourseEdit() {

  return (
    <div className='course-edit'>
      <h2>Edit course</h2>
      <AddEditCourse type={AddEditFormType.EDIT} courseType={CourseType.VIDEO}></AddEditCourse>
    </div>
  );
}

export default CourseEdit;
