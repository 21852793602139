import { ActionCardAlign } from "../../enums/actio-card-align.enum";
import { InteractiveCardType } from "../../enums/interactive-card-type";
import { InteractiveCard } from "../../interfaces/interactive-card.interface";

export class PictureCard implements InteractiveCard {
  // metadata
  id: number = 0;
  dx: number = 0;
  dy: number = 0;
  dxRel: number = 0;
  dyRel: number = 0;
  dxTemp: number = 0;
  dyTemp: number = 0;
  type: InteractiveCardType = InteractiveCardType.PICTURE;
  showTime: number = 0;
  hideTime: number = 0;
  pauseVideo: boolean = true;
  hasTimer: boolean = false;
  // specific metadata
  duration: number = 5; // seconds
  showCaption: boolean = false;
  imageWidth:number = 30; // percentage
  imageHeigth:number = 30;
  
  // content
  caption: string = 'Beautiful picture';
  imageUrl: string = 'https://images.theconversation.com/files/443350/original/file-20220131-15-1ndq1m6.jpg?ixlib=rb-1.1.0&rect=0,0,3354,2464&q=45&auto=format&w=926&fit=clip';

  // style
  color: string = '';

  constructor(data:any) {
    this.id = Math.floor(Math.random() * 1000000);
    Object.assign(this, data);
  }
}

export { }