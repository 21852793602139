import Button from "@mui/material/Button";
import { Paper, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './qa.scss';
import { ThemeType } from "../../enums/theme-type.enum";
import { useTheme } from "../../hooks/use-theme";
import { useQuestions, createQuestion, createAnswer, deleteQuestion, updateQuestion, updateAnswer, deleteAnswer } from "../../hooks/use-qa";
import { useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import AuthService from "../../services/AuthService";
import AlertDialog from "../../components/alert-dialog/alert-dialog";

const dateOptions = {
  month: "long",
  day: "numeric",
  year: "numeric",
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};

function QA() {
  const { id } = useParams();
  const { theme } = useTheme();
  const { isLoading, data } = useQuestions(id ?? '0');
  const [submitted, setSubmitted] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [editQuestion, setEditQuestion] = useState('');
  const [userId, setUserId] = useState('');
  const [editQuestionId, setEditQuestionId] = useState(-1);
  const [responseQuestionId, setResponseQuestionId] = useState(-1);
  const [answer, setAnswer] = useState('');
  const [editAnswer, setEditAnswer] = useState('');
  const [editAnswerId, setEditAnswerId] = useState(-1);
  const [deleteQuestionId, setDeleteQuestionId] = useState(-1);
  const [deleteAnswerId, setDeleteAnswerId] = useState(-1);
  const [alertAnswerOpen, setAlertAnswerOpen] = useState(false);
  const queryClient = useQueryClient()

  useEffect(() => {
    const uid = AuthService?.getParsedToken()?.sub;
    if (uid) {
      setUserId(uid);
    }
  }, [])

  const submitQuestion = async () => {
    setSubmitted(true);
    if (!id || !title || !question) return;
    try {
      await createQuestion(id, title, question);
      setSubmitted(false);
      setTitle('');
      setQuestion('');
      queryClient.invalidateQueries('questions')
    } catch (error) {
      console.log("Error creating question", error)
    }
  }

  const handleUpdateQuestion = async () => {
    if (!id) return;
    try {
      const resp = await updateQuestion(id, {
        id: editQuestionId,
        title: editTitle,
        description: editQuestion
      });
      setEditQuestionId(-1);
      queryClient.invalidateQueries('questions')
    } catch (error) {
      console.log("Error updating question", error)
    }
  }

  const handleUpdateAnswer = async (questionId: number, answerId: number) => {
    if (!id) return;
    try {
      const resp = await updateAnswer(id, questionId, answerId, editAnswer);
      setEditAnswerId(-1);
      queryClient.invalidateQueries('questions')
    } catch (error) {
      console.log("Error updating answer", error)
    }
  }

  const submitAnswer = async () => {
    if (!id || !answer || !responseQuestionId) return;
    try {
      await createAnswer(id, responseQuestionId, answer);
      queryClient.invalidateQueries('questions')
      setResponseQuestionId(-1);
    } catch (error) {
      console.log("Error creating response", error)
    }
  }

  const handleReply = (questionId: number) => {
    setAnswer('');
    setResponseQuestionId(questionId);
  }

  const handleEditQuestion = async (questionId: number, questionTitle: string, questionDescription: string) => {
    setEditTitle(questionTitle);
    setEditQuestion(questionDescription);
    setEditQuestionId(questionId);
  }

  const handleEditAnswer = async (answerId: number, answerDescription: string) => {
    setEditAnswer(answerDescription);
    setEditAnswerId(answerId);
  }

  const handleDeleteQuestion = async () => {
    try {
      if (!id || !deleteQuestionId) return;
      await deleteQuestion(id, deleteQuestionId);
      queryClient.invalidateQueries('questions')
    } catch (error) {
      console.log("Error creating response", error)
    }
  }

  const handleDeleteAnswer = async () => {
    try {
      if (!id || !deleteQuestionId || !deleteAnswerId) return;
      await deleteAnswer(id, deleteQuestionId, deleteAnswerId);
      queryClient.invalidateQueries('questions')
      setDeleteAnswerId(-1);
      setDeleteQuestionId(-1);
    } catch (error) {
      console.log("Error creating response", error)
    }
  }

  if (isLoading || !data) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div className={`qa ${theme === ThemeType.DARK ? 'qa--dark' : 'qa--light'}`}>
        <h2>Ask a question</h2>
        <div className="qa__input">
          <TextField
            className="input-elem"
            id="outlined-textarea"
            label="Title"
            color="secondary"
            placeholder="Title..."
            name="title"
            value={title}
            onChange={(event) => { setTitle(event.target.value) }}
            error={submitted && !title}
          />
        </div>
        <div className="qa__input">
          <div className="row">
            <TextField
              className="input-elem"
              id="outlined-multiline-flexible"
              label="Details"
              placeholder="Question..."
              multiline
              color="secondary"
              minRows={5}
              maxRows={50}
              name="question"
              value={question}
              error={submitted && !question}
              onChange={(event) => { setQuestion(event.target.value) }}
            />
          </div>
        </div>
        <div>
          <Button className="action-button" variant="contained" onClick={submitQuestion}>Submit</Button>
        </div>
        <h2>Q&A</h2>
        {data.data.map((question: any) => (

          <div key={question.id}>
            <Paper elevation={3} className="question-list-element">
              {editQuestionId != question.id &&
                <>
                  <div className="question-list-element-title">
                    <span className="user">
                      <FontAwesomeIcon icon={solid('user')} aria-hidden="true" />
                      <p className="user-name">{question.userName}</p>
                    </span>
                    <h3>{question.title}</h3>
                    <p className="date">{new Date(question.date).toLocaleDateString("en-US", (dateOptions as any))}</p>
                  </div>
                  <div>
                    <ReactMarkdown children={question.description} remarkPlugins={[remarkGfm]} />
                  </div>
                  <div className="row">
                    <p className="action" onClick={() => { handleReply(question.id) }}>Reply</p>
                    {userId == question.userId &&
                      <>
                        <p className="action" onClick={() => { handleEditQuestion(question.id, question.title, question.description) }}>Edit</p>
                        <p className="action" onClick={() => {
                          setDeleteQuestionId(question.id)
                          setAlertOpen(true)
                        }}>Delete</p>
                        <AlertDialog
                          title="Delete Question?"
                          open={alertOpen}
                          setOpen={setAlertOpen}
                          onConfirm={handleDeleteQuestion}
                        >
                          Are you sure you want to delete this question?
                        </AlertDialog>
                      </>
                    }
                  </div>
                </>
              }
              {editQuestionId == question.id &&
                <>
                  <div className="qa__input">
                    <TextField
                      className="input-elem"
                      label="Title"
                      color="secondary"
                      placeholder="Title..."
                      value={editTitle}
                      onChange={(event) => { setEditTitle(event.target.value) }}
                    />
                  </div>
                  <div className="qa__input">
                    <div className="row">
                      <TextField
                        className="input-elem"
                        label="Details"
                        placeholder="Question..."
                        multiline
                        color="secondary"
                        minRows={5}
                        maxRows={50}
                        value={editQuestion}
                        onChange={(event) => { setEditQuestion(event.target.value) }}
                      />
                    </div>
                  </div>
                  <div>
                    <Button className="action-button" variant="contained" size="small" onClick={handleUpdateQuestion}>Submit</Button>
                    <Button className="action-button" variant="contained" size="small" onClick={() => setEditQuestionId(-1)}>Cancel</Button>
                  </div>
                </>
              }
            </Paper>
            {responseQuestionId == question.id &&
              <div>
                <div className="qa__input">
                  <div className="row">
                    <TextField
                      className="input-elem"
                      id="outlined-multiline-flexible"
                      label="Answer"
                      placeholder="Answer..."
                      multiline
                      color="secondary"
                      minRows={5}
                      maxRows={50}
                      name="answer"
                      value={answer}
                      onChange={(event) => { setAnswer(event.target.value) }}
                    />
                  </div>
                </div>
                <div>
                  <Button className="action-button" variant="contained" onClick={submitAnswer}>Submit</Button>
                </div>
              </div>
            }

            <div className="response-list">
              {question.responses.map((resp: any) => (
                <Paper key={resp.id} className="response-list-element">
                  {editAnswerId != resp.id &&
                    <>
                      <div className="response-list-element-title">
                        <span className="user">
                          {!resp.isTeacher && <FontAwesomeIcon icon={solid('user')} aria-hidden="true" />}
                          {resp.isTeacher &&
                            <Tooltip title="Teacher response" arrow placement="top-start">
                              <div>
                                <FontAwesomeIcon icon={solid('user-check')} aria-hidden="true" />
                              </div>
                            </Tooltip>}
                          <p className="user-name">{resp.name}</p>
                        </span>
                        <p className="date">{new Date(resp.date).toLocaleDateString("en-US", (dateOptions as any))}</p>
                      </div>
                      <div>
                        <ReactMarkdown children={resp.description} remarkPlugins={[remarkGfm]} />
                      </div>
                      <div className="row">
                        {userId == resp.user &&
                          <>
                            <p className="action" onClick={() => { handleEditAnswer(resp.id, resp.description) }}>Edit</p>
                            <p className="action" onClick={() => {
                              setDeleteQuestionId(question.id)
                              setDeleteAnswerId(resp.id)
                              setAlertAnswerOpen(true)
                            }}>Delete</p>
                            <AlertDialog
                              title="Delete Answer?"
                              open={alertAnswerOpen}
                              setOpen={setAlertAnswerOpen}
                              onConfirm={handleDeleteAnswer}
                            >
                              Are you sure you want to delete this answer?
                            </AlertDialog>
                          </>
                        }
                      </div>
                    </>
                  }
                  {editAnswerId == resp.id &&
                    <>
                      <div className="qa__input">
                        <div className="row">
                          <TextField
                            className="input-elem"
                            label="Details"
                            placeholder="Answer..."
                            multiline
                            color="secondary"
                            minRows={5}
                            maxRows={50}
                            value={editAnswer}
                            onChange={(event) => { setEditAnswer(event.target.value) }}
                          />
                        </div>
                      </div>
                      <div>
                        <Button className="action-button" variant="contained" size="small" onClick={() => { handleUpdateAnswer(question.id, resp.id) }}>Submit</Button>
                        <Button className="action-button" variant="contained" size="small" onClick={() => setEditAnswerId(-1)}>Cancel</Button>
                      </div>
                    </>
                  }
                </Paper>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default QA;
