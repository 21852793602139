import { SyntheticEvent, KeyboardEvent, useEffect, useRef, useState, ChangeEvent } from "react";
import userIcon from '../../assets/user.png';
import UserService from '../../services/UserService';
import MessageService from '../../services/MessageService';
import ConversationPartner from '../conversation-partner/conversation-partner';
import io from "socket.io-client";
import AuthService from "../../services/AuthService";
import { ConversationPartnerProps } from "../conversation-partner/conversation-partner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BsCameraVideoFill, BsCameraVideoOffFill } from 'react-icons/bs';
import './chat.scss';

type OfferMessage = {
  offer: undefined,
  icecandidates: RTCIceCandidate[],
  receiver: string,
  sender: string,
  type: string
};
const offerMessage: OfferMessage = {
  offer: undefined,
  icecandidates: [],
  receiver: '',
  sender: '',
  type: ''
};

let socket: any = null;
let socketUrl = process.env.REACT_APP_WEBSOCKET_HOST ?? 'http://localhost:5000';

export type Message = {
  id: string | null,
  message: string,
  sender: string | null,
  receiver: string | null,
  date: Date | null,
};
export class ChatProps {
}

function Chat(props: ChatProps) {
  const [message, setMessage] = useState<string>('');
  const [userId, setUserId] = useState<string>(null as any);
  const [userName, setUserName] = useState(null);
  const [userPicture, setUserPicture] = useState(null);
  const [conversationPartners, setConversationPartners] = useState<ConversationPartnerProps[]>([]);
  const [filteredConversationPartners, setFilteredConversationPartners] = useState<ConversationPartnerProps[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<ConversationPartnerProps>(null as any);
  const [messages, setMessages] = useState<Message[]>([]);
  const [search, setSearch] = useState('');
  const [stream, setStream] = useState<MediaStream>(null as any);
  const [peer, setPeer] = useState(new RTCPeerConnection());
  const [activeCall, setActiveCall] = useState(false);
  const [muted, setMuted] = useState(false);
  const [videoActive, setVideoActive] = useState(true);
  const [fetchActivePartners, setFetchActivePartners] = useState(false);

  const srcVideo = useRef<HTMLVideoElement>(null);
  const respVideo = useRef<HTMLVideoElement>(null);
  const messagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    didMount();
    return () => {
      socket.off('message', handleMessage);
      socket.off('askForUserId', registerUserId);
      socket.off('deleteConversation', handleDeleteConversation);
      socket.off('setActivePartner', setActivePartner);
      socket.off('setInactivePartner', setInactivePartner);
      socket.off('alertAboutCurrentlyActiveUsers', setPartnerStatuses);
    }
  }, [])
  useEffect(() => {
    if (fetchActivePartners) {
      socket.emit('getActivePartners', socket.id);
    }
    return () => {
      setFetchActivePartners(false);
    }
  }, [conversationPartners])

  const didMount = async () => {
    socket = io(socketUrl);
    // Chat
    socket.on('message', handleMessage);
    socket.on('deleteConversation', handleDeleteConversation);
    socket.on('askForUserId', registerUserId);
    socket.on('setActivePartner', setActivePartner);
    socket.on('setInactivePartner', setInactivePartner);
    socket.on('alertAboutCurrentlyActiveUsers', setPartnerStatuses);
    // Videochat
    socket.on('icuData', handleOffer);
    // let user = await UserService.ensureUserExists();
    // if (user === true) {
    let user = await UserService.getLoggedInUser();
    // }
    if (!user) {
      console.log('User not found!');
    }
    let partners = null;
    if (AuthService.hasRole(['teacher'])) {
      partners = await UserService.getAllUsers();
    } else {
      partners = await UserService.getTeachers();
    }
    setUserId(user.id ?? UserService.getUserId());
    setUserName(user.name ?? UserService.getUsername());
    setUserPicture(user.picture ?? '');
    setConversationPartners(partners);
    setFetchActivePartners(true);
  }

  const handleMessage = (mess: Message) => {
    if (mess !== null) {
      setMessages((oldMessages) => [...oldMessages, mess]);
      setMessage('');
      scrollToBottom();
    }
  }
  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }
  const handleDeleteConversation = (partnerId: string) => {
    if (selectedPartner && selectedPartner.id == partnerId) {
      setMessages([]);
    }
  }
  const registerUserId = async () => {
    await socket.emit('registerId', UserService.getUserId());
  }

  const sendMessage = async () => {
    if (message.trim() == '') return;
    const messageObject = {
      id: null,
      date: null,
      sender: userId,
      receiver: selectedPartner.id,
      message
    };
    const sentMessage = await MessageService.postMessage(messageObject);
    if (sentMessage !== null) {
      setMessages([...messages, sentMessage]);
      setMessage('');
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
      socket.emit('message', messageObject);
    }
  }

  const deleteConversation = async () => {
    const result = await MessageService.deleteConversation(userId, selectedPartner.id);
    if (result === true) {
      socket.emit('deleteConversation', { sender: userId, receiver: selectedPartner.id });
      setMessages([]);
    }
  }

  const handleTextChange = (event: SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    setMessage(target.value);
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key == "Enter") {
      sendMessage();
    }
  }

  // Runs when a partner is selected
  const selectPartner = async (partnerId: string) => {
    if (selectedPartner == null || selectedPartner.id !== partnerId) {
      let selectedIndex = conversationPartners.findIndex(x => x.id === partnerId);
      let partners = [...conversationPartners];
      let partner = { ...partners[selectedIndex] };
      partner.active = true;
      partners[selectedIndex] = partner;

      const mess = await MessageService.getMessages(userId, partner.id);
      setMessages(mess);

      if (selectedPartner !== null) {
        selectedIndex = conversationPartners.findIndex(x => x.id === selectedPartner.id);
        let oldPartner = { ...partners[selectedIndex] };
        oldPartner.active = false;
        partners[selectedIndex] = oldPartner;
      }

      setConversationPartners(partners);
      console.log(222);
      console.log(partners);
      setSelectedPartner(partner);
      scrollToBottom();
    }
  }

  const setActivePartner = async (partnerId: string) => setPartnerStatus(partnerId, 'online');
  const setInactivePartner = async (partnerId: string) => setPartnerStatus(partnerId, 'busy');

  // This will update the partner status who wrote a message
  const setPartnerStatus = async (partnerId: string, status: string) => {
    if (conversationPartners.length == 0) return;
    let selectedIndex = conversationPartners.findIndex(x => x.id === partnerId);
    if (selectedIndex == -1) return;
    let partners = [...conversationPartners];
    let partner = { ...partners[selectedIndex] };
    partner.status = status;
    partners[selectedIndex] = partner;
    setConversationPartners(partners);
  }

  // When opening the chat, this will get all the currently active partners from the cache
  const setPartnerStatuses = async (partnerIds: string[]) => {
    let newConversationPartners: ConversationPartnerProps[] = conversationPartners.map(partner => {
      if (partnerIds.includes(partner.id)) {
        return {
          ...partner,
          status: 'online'
        };
      }
      return partner;
    });
    setConversationPartners(newConversationPartners);
  }

  const partnerPicture = () => {
    return selectedPartner.picture || userIcon;
  }

  const getUserPicture = () => {
    return userPicture || userIcon;
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    let tmpFilteredConversationPartners: ConversationPartnerProps[] = [];
    if (conversationPartners) {
      tmpFilteredConversationPartners = conversationPartners.filter(partner => partner.name.includes(searchStr));
    }
    setSearch(searchStr);
    setFilteredConversationPartners(tmpFilteredConversationPartners);

  }

  ///VIDEO CHAT PART
  const startConnection = (stream: MediaStream) => {
    console.log(stream);
    let icecandidates: RTCIceCandidate[] = [];
    stream.getTracks().forEach((track) => {
      peer.addTrack(track, stream);
    });

    peer.onicecandidate = (e) => {
      if (e.candidate) {
        icecandidates.push(e.candidate);
      }
    };

    peer.addEventListener("icegatheringstatechange", () => iceGatheringStateChange(icecandidates, selectedPartner.id, 'offer'));
    peer.oniceconnectionstatechange = iceConnectionStateChange;
    peer.createOffer()
      .then((offer) => {
        offerMessage.offer = offer as any;
        peer.setLocalDescription(offer);
      })
      .catch((error) => console.error(`Unable to establish P2P communication: ${error}`));
  };
  const iceConnectionStateChange = (ev: any) => {
    if (ev.target['iceConnectionState'] === "failed" ||
      ev.target['iceConnectionState'] === "disconnected" ||
      ev.target['iceConnectionState'] === "closed") {
      if (activeCall) {
        closeCall();
      }
    }
  };
  const iceGatheringStateChange = (icecandidates: RTCIceCandidate[], receiver: string, type: string) => {
    if (peer.iceGatheringState == "complete") {
      offerMessage.icecandidates = icecandidates;
      if (offerMessage.offer != undefined) {
        offerMessage.receiver = receiver;
        offerMessage.sender = userId;
        offerMessage.type = type;
        socket.emit('icuData', offerMessage);
      }
    }
  }

  const onConnect = async () => {
    try {
      const rtcStream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false }, video: true });
      await setStream(rtcStream);
      setActiveCall(true);
      if (srcVideo.current) {
        srcVideo.current.srcObject = rtcStream;
        await startConnection(rtcStream);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeCall = () => {
    peer.close();
    stream.getTracks().forEach((track) => {
      track.stop();
    });

    stopVideo(srcVideo.current);
    stopVideo(respVideo.current);
    setActiveCall(false);
    setPeer(new RTCPeerConnection());
  }
  const stopVideo = (video: HTMLVideoElement | null) => {
    if (video && video.srcObject) {
      const stream = video.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });
      video.srcObject = null;
    }
  }
  const handleOffer = (data: OfferMessage) => {
    if (data.type == 'offer') {
      createResponse(data);
    } else {
      handleResponse(data);
    }
  }

  const createResponse = async (data: any) => {
    console.log(data);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false }, video: true });
    setStream(stream);
    setActiveCall(true);
    if (srcVideo.current) {
      srcVideo.current.srcObject = stream;
    }

    let icecandidates: RTCIceCandidate[] = [];
    stream.getTracks().forEach((track) => {
      peer.addTrack(track, stream);
    });
    peer.ontrack = (track) => {
      try {
        if (respVideo.current) {
          respVideo.current.srcObject = track.streams[0];
        }
      } catch (error) {
        console.log(error);
      }
    };
    peer.addEventListener("icegatheringstatechange", () => iceGatheringStateChange(icecandidates, data.sender, 'answer'));
    peer.oniceconnectionstatechange = iceConnectionStateChange;

    peer.onicecandidate = (e) => {
      if (e.candidate) {
        icecandidates.push(e.candidate);
      }
    };

    peer.setRemoteDescription(new RTCSessionDescription(data.offer))
      .then(() => peer.createAnswer())
      .then((answer) => {
        data.icecandidates.forEach((candidate: RTCIceCandidate) => {
          peer.addIceCandidate(candidate);
        });
        peer.setLocalDescription(answer);
        offerMessage.offer = answer as any;
      })
      .catch((error) => console.error(`Unable to establish P2P communication: ${error}`));
  };

  const handleResponse = (data: any) => {
    peer.setRemoteDescription(new RTCSessionDescription(data.offer)).then(() => {
      data.icecandidates.forEach((candidate: RTCIceCandidate) => {
        peer.addIceCandidate(candidate);
      });
    });
    peer.ontrack = (event) => {
      try {
        if (respVideo.current) {
          respVideo.current.srcObject = event.streams[0];
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  const toggleMute = () => {
    stream.getTracks().forEach((t) => {
      if (t.kind === 'audio') t.enabled = !t.enabled;
    });
    setMuted(prevMuted => !prevMuted);

  }

  const toggleVideo = () => {
    stream.getTracks().forEach((t) => {
      if (t.kind === 'video') t.enabled = !t.enabled;
    });
    setVideoActive(prevVideoActive => !prevVideoActive);
  }


  return (
    <div className="chat">
      <div id="frame">
        <div id="sidepanel">
          <div id="profile">
            <div className="wrap">
              <img id="profile-img" src={getUserPicture()} className="online" alt="" />
              <p>{userName || ""}</p>
              <FontAwesomeIcon icon={solid('chevron-down')} className="expand-button" aria-hidden="true" />
              {/* <i className="fa fa-chevron-down expand-button" aria-hidden="true"></i> */}
              <div id="status-options">
                <ul>
                  <li id="status-online" className="active"><span className="status-circle"></span>
                    <p>Online</p>
                  </li>
                  <li id="status-away"><span className="status-circle"></span>
                    <p>Away</p>
                  </li>
                  <li id="status-busy"><span className="status-circle"></span>
                    <p>Busy</p>
                  </li>
                  <li id="status-offline"><span className="status-circle"></span>
                    <p>Offline</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="search">
            <label htmlFor=""><FontAwesomeIcon icon={solid('search')} aria-hidden="true" /></label>
            <input type="text" placeholder="Search contacts..." onChange={handleSearch} />
          </div>
          <div id="contacts">
            <ul>
              {!search && conversationPartners && conversationPartners.map((partner: ConversationPartnerProps) => {
                if (userId !== partner.id) {
                  return <ConversationPartner
                    key={partner.id}
                    id={partner.id}
                    name={partner.name}
                    picture={partner.picture || ''}
                    status={partner.status || ''}
                    active={partner.active || false}
                    selectPartner={selectPartner}
                  />
                }
              })}
              {search && filteredConversationPartners && filteredConversationPartners.map((partner: ConversationPartnerProps) => {
                if (userId !== partner.id) {
                  return <ConversationPartner
                    key={partner.id}
                    id={partner.id}
                    name={partner.name}
                    picture={partner.picture || ''}
                    status={partner.status || ''}
                    active={partner.active || false}
                    selectPartner={selectPartner}
                  />
                }
              })}
            </ul>
          </div>
          {activeCall &&
            <div id="bottom-bar">
              <button id="addcontact" onClick={toggleMute}>
                {!muted &&
                  <span><FontAwesomeIcon icon={solid('microphone')} aria-hidden="true" />Mute</span>
                }
                {muted &&
                  <span><FontAwesomeIcon icon={solid('microphone-slash')} aria-hidden="true" />Unmute</span>
                }
              </button>
              <button id="settings" onClick={toggleVideo}>
                {videoActive &&
                  <span><BsCameraVideoOffFill />Turn off camera</span>
                }
                {!videoActive &&
                  <span><BsCameraVideoFill />Turn on camera</span>
                }
              </button>
            </div>
          }
        </div>
        <div className="content">
          {selectedPartner &&
            <div className="contact-profile">
              <img src={partnerPicture()} alt="" />
              <p>{selectedPartner.name}</p>
              <div className="social-media">
                {!activeCall &&
                  // <i className="fa fa-video" aria-hidden="true" onClick={onConnect}></i>
                  <BsCameraVideoFill onClick={onConnect} />
                }{activeCall &&
                  <BsCameraVideoOffFill onClick={closeCall} />
                  // <i className="fa fa-video-slash" aria-hidden="true" onClick={closeCall}></i>
                }
                <FontAwesomeIcon icon={solid('trash')} aria-hidden="true" onClick={() => { if (window.confirm('Are you sure you wish to delete all the messages?')) deleteConversation() }} />
              </div>
            </div>
          }
          {selectedPartner && !activeCall &&
            <>
              <div className="messages" ref={messagesRef}>
                <ul>
                  {messages.map((mess, index) =>
                    <li className={userId === mess.sender ? 'sent' : 'reply'} key={index}>
                      <img src={userId === mess.sender ? getUserPicture() : partnerPicture()} alt="" />
                      <p>
                        <span>{mess.date && (mess.date as unknown as string).split('T')[1].split('.')[0]}</span>
                        {mess.message}
                        {/* <span>{mess.date && `${mess.date.getHours().toString()}:${mess.date.getMinutes().toString()}:${mess.date.getSeconds().toString()}`}</span> */}
                      </p>
                    </li>
                  )}

                </ul>
              </div>
              <div className="message-input">
                <div className="wrap">
                  <input type="text" onChange={handleTextChange} onKeyDown={handleKeyDown} placeholder="Write your message..." value={message} />
                  {/* <button className="submit" onClick={sendMessage}><i className="fa fa-paper-plane" aria-hidden="true"></i></button> */}
                  <button className="submit" onClick={sendMessage}><FontAwesomeIcon icon={solid('paper-plane')} /></button>
                </div>
              </div>
            </>
          }
          {activeCall &&
            <div style={{ marginLeft: 'em', height: '80%' }}>
              <video style={{ height: '70%' }} id="respVideo" ref={respVideo} autoPlay></video>
              <video style={{ height: '30%' }} id="srcVideo" ref={srcVideo} autoPlay muted></video>
            </div>
          }

        </div>

      </div>
    </div>

  );
}
export default Chat;
