import React, { useEffect, useState } from 'react';
import './interactive-video-editor.scss';
import VideoJS from '../../components/video-js/video-js';
import { VideoJSUseMode } from '../../enums/videojs-use-mode.enum';
import { InteractiveVideoData } from '../../models/interactive-video/interactive-video.model';
import { InteractiveCard } from '../../interfaces/interactive-card.interface';
import { useInteractiveVideo } from '../../hooks/use-interactive-video';
import { CourseVideoData } from '../../models/course-data/courseData.model';
import InteractiveService from '../../services/InteractiveService';
import { InteractiveCardType } from '../../enums/interactive-card-type';
import { ActionCard } from '../../models/interactive-video/action-card.model';
import { CourseCard } from '../../models/interactive-video/course-card.model';
import { PictureCard } from '../../models/interactive-video/picture-card.model';
import { QuestionCard } from '../../models/interactive-video/question-card.model';

export class InteractiveVideoEditorProps {
  video: CourseVideoData = new CourseVideoData();
  courseId: string = '';
  // videoSrc: string = '';
  interactiveCards?: InteractiveCard[];
  setInteractiveCards?: any;
  setNewInteractiveCards?: any;
}

let refetch: boolean = true;

function InteractiveVideoEditor(props: InteractiveVideoEditorProps) {
  const playerRef = React.useRef(null);
  // const interactiveVideoData = useInteractiveVideo(props.courseId, parseInt(props.video.id), refetch);
  const [interactiveVideoData, setinteractiveVideoData] = useState<any>([]);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: props.video.link ?? '/do_I_wanna.mp4',
      type: 'video/mp4'
    }],
    plugins: [
      // markers: []
      // hotkeys: {

      // }
    ]
  }

  useEffect(() => {
    // refetch = interactiveVideoData.status !== 'success';
    const fetchData = async () => {
      const data = await InteractiveService.getIntActions(props.courseId, parseInt(props.video.id));
      console.log("fucking dta", data);
      const processedData =  data?.map((interactivity: InteractiveCard) => {
        if(interactivity.type === InteractiveCardType.ACTION) {
            return new ActionCard(interactivity);
        } else if(interactivity.type === InteractiveCardType.COURSE) {
            return new CourseCard(interactivity);
        } else if(interactivity.type === InteractiveCardType.PICTURE) {
            return new PictureCard(interactivity);
        } 
        // else if(interactivity.type === InteractiveCardType.QUESTION) {
            return new QuestionCard(interactivity);
        // }
    })
      
      setinteractiveVideoData(processedData);
    }

    fetchData();

  }, [])

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };
  return (
    <div className="course-watching">
      <h2>Interactive Video Editor</h2>

      <VideoJS options={videoJsOptions}
        onReady={handlePlayerReady}
        videoList={[]}
        videoJSUseMode={VideoJSUseMode.EDIT} actualVideoIndex={-1}
        interactiveCards={interactiveVideoData}
        setParentInteractiveCards={props.setInteractiveCards}
        setParentNewInteractiveCards={props.setNewInteractiveCards}
      />

      <div>---------------------------------------</div>
    </div>
  );
}

export default InteractiveVideoEditor;
