import { useQuery, useMutation, useQueryClient } from "react-query";
import { Category } from "../models/course-data/courseData.model.js";
import HttpService from '../services/HttpService.js';
const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

export const useCategories = () => {
    return useQuery('categories', async () => {
        const resp = await axios.get(`${baseApiUrl}/categories`);
        return resp.data;
    });
}

const createCategory = async (category: Category) => {
  try {
    const response = await axios.post(`${baseApiUrl}/categories`, category);
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const useCreateCategory = () => {
    return useMutation(createCategory);
    
    // const queryClient = useQueryClient()
    // return useMutation(createCategory, {
    //     // onSuccess: data => {
    //     //   /** Query Invalidation Start */
    //     //   // queryClient.invalidateQueries('super-heroes')
    //     //   /** Query Invalidation End */

    //     //   /** Handling Mutation Response Start */
    //     // queryClient.setQueryData('super-heroes', oldQueryData => {
    //     //   return {
    //     //     ...oldQueryData,
    //     //     data: [...oldQueryData.data, data.data]
    //     //   }
    //     // })
    //     //   /** Handling Mutation Response Start */
    //     // },
    //     /**Optimistic Update Start */
    //     onMutate: async newHero => {
    //         await queryClient.cancelQueries('super-heroes')
    //         const previousHeroData = queryClient.getQueryData('super-heroes')
    //         queryClient.setQueryData('super-heroes', oldQueryData => {
    //             return {
    //                 ...oldQueryData,
    //                 data: [
    //                     ...oldQueryData.data,
    //                     { id: oldQueryData?.data?.length + 1, ...newHero }
    //                 ]
    //             }
    //         })
    //         return { previousHeroData }
    //     },
    //     onError: (_err, _newTodo, context) => {
    //         queryClient.setQueryData('super-heroes', context.previousHeroData)
    //     },
    //     onSettled: () => {
    //         queryClient.invalidateQueries('super-heroes')
    //     }
    //     /**Optimistic Update End */
    // })
}