import { useQuery } from "react-query";
import { QuizResultsOfAVideo } from "../models/statistics/quizResultsData.model";
import HttpService from '../services/HttpService.js';
const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

export const useQuizResults = () => {
  const response = useQuery<QuizResultsOfAVideo[]>(['use-quiz-results'], async ():Promise<any> => {
    const resp = await axios.get(`${baseApiUrl}/profile/quizzes`);
    
    return resp.data;
  });
console.log(response.data);

  // response.data = response.data?.map(res => {return new QuizResultsOfAVideo(res)})

  return response;
}