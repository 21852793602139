import { ActionCardAlign } from "../../enums/actio-card-align.enum";
import { InteractiveCardType } from "../../enums/interactive-card-type";
import { InteractiveCard } from "../../interfaces/interactive-card.interface";

export enum ActionCardContentType {
  TITLE = 'title',
  TEXT = 'text',
  CODE = 'code'
}

export class ActionCardContent {
  id: number = 0;
  type: ActionCardContentType = ActionCardContentType.TEXT;
  content: string = '';
  order: number = 0;
}

export class ActionCard implements InteractiveCard {
  // metadata
  id: number = 0;
  dx: number = 0;
  dy: number = 0;
  dxRel: number = 0;
  dyRel: number = 0;
  dxTemp: number = 0;
  dyTemp: number = 0;
  type: InteractiveCardType = InteractiveCardType.ACTION;
  showTime: number = 0;
  hideTime: number = 0;
  pauseVideo: boolean = true;
  hasTimer: boolean = false;
  alignTextBox: ActionCardAlign = ActionCardAlign.BOTTOM;
  duration: number = 3; // seconds

  // content
  // title: string = 'Some title';
  // text: string = 'Some text';
  contentBlocks: ActionCardContent[] = [];

  // style
  cardColor: string = 'black';

  constructor(data: any) {
    Object.assign(this, data);
    if(this.id === 0) {
      this.id = Math.floor(Math.random() * 1000000);
    }
  }
}

export { }