import { Message } from '../components/chat/chat.js';
import HttpService from './HttpService.js';

const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

const getMessages = async (userId1: string, userId2: string): Promise<Message[]> => {
  const response = await axios.get(`${baseApiUrl}/messages`, { params: { userId1, userId2 } });
  if (response.status == 200) {
    return response.data.messages || [];
  }
  return [];
}

const postMessage = async (message: Message): Promise<Message | null> => {
  const response = await axios.post(`${baseApiUrl}/messages`, {
    sender: message.sender,
    receiver: message.receiver,
    message: message.message
  });
  
  if (response.status == 201) {
    return response.data.message || null;
  }
  return null;
}

const deleteMessage = async (messageId: number): Promise<boolean> => {
  const response = await axios.delete(`${baseApiUrl}/messages/${messageId}`);
  return response.status == 204;
};

const deleteConversation = async (userId1: string, userId2: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`${baseApiUrl}/messages`, { data: { userId1, userId2 } });
    console.log(response.data);
    
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return false;
};

const MessageService = {
  getMessages,
  postMessage,
  deleteMessage,
  deleteConversation
};

export default MessageService;
