import { useQuery } from "react-query";
import { InteractiveCardType } from "../enums/interactive-card-type";
import { InteractiveCard } from "../interfaces/interactive-card.interface";
import { ActionCard } from "../models/interactive-video/action-card.model";
import { CourseCard } from "../models/interactive-video/course-card.model";
import { InteractiveVideoData } from "../models/interactive-video/interactive-video.model";
import { PictureCard } from "../models/interactive-video/picture-card.model";
import { QuestionCard } from "../models/interactive-video/question-card.model";
import InteractiveService from "../services/InteractiveService";

export const useInteractiveVideo = (courseId: string | undefined, videoId: number | undefined, refetch?: boolean) => {
    const response = useQuery(`interactive/${courseId}/${videoId}`, () => {
        if(!courseId || !videoId) return null;
        return InteractiveService.getIntActions(courseId, videoId);
    // }, { enabled: refetch, staleTime: 9999999, cacheTime: 9999999 });
    }, { enabled: !!courseId && !!videoId });
    console.log(999, response, refetch, courseId, videoId );

    response.data = response.data?.map((interactivity: InteractiveCard) => {
        if(interactivity.type === InteractiveCardType.ACTION) {
            return new ActionCard(interactivity);
        } else if(interactivity.type === InteractiveCardType.COURSE) {
            return new CourseCard(interactivity);
        } else if(interactivity.type === InteractiveCardType.PICTURE) {
            return new PictureCard(interactivity);
        } 
        // else if(interactivity.type === InteractiveCardType.QUESTION) {
            return new QuestionCard(interactivity);
        // }
    })
    

    return {
        ...response,
        data: response.data
    }
}