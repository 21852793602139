import { FaHeart, FaRegHeart, FaShoppingBasket, FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { getCentPart, getIntPart, enumToColorClass } from '../../utils/functions';
import './small-tv.scss';
import HttpService from '../../services/HttpService';

export enum TvColor {
  BROWN,
  GREY
}

class SmallTvProps {
  id: string = '';
  title: string = '';
  price?: number = 0;
  rating?: number = 5;
  studentNr?: number = 0;
  picture: string = '';
  onClickBasePath?: string = '';
  favourite?: boolean = false;
  purchased?: boolean = false;

  hasSideButtons?: boolean = false;
  hasBottomButton?: boolean = false;
  color?: TvColor = TvColor.GREY;
  toggleFavourites?: any;
}
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;
const axios = HttpService.getAxiosClient();

function SmallTv(props: SmallTvProps) {

  let navigate = useNavigate();
  const navigateTo = (path: string) => {
    return () => navigate(path)
  };

  const redirectToCheckout = async () => {
    try {
      if (!AuthService.isLoggedIn()) {
        AuthService.doLogin();
      }
      const body = await axios.post(`${baseApiUrl}/checkout`, { courseId: props.id });
      window.location.href = body.data.url;
    } catch (error) {
      console.log("Unauthorized to purchase");
      return null;
    }
  }

  return (
    // Change onclick
    <div className='small-tv'>
      <div className='small-tv__antennas'>
        <div className='small-tv__antenna small-tv__antenna--left'>
        </div>
        <div className='small-tv__antenna small-tv__antenna--right'>
        </div>
      </div>
      {!AuthService.hasRole(['teacher']) && !props.purchased && props.price !== undefined &&
        <div className='small-tv__price'>
          <div className='small-tv__price__text'>
            $
            {getIntPart(props.price)}
            <sup>{getCentPart(props.price)}</sup>
          </div>
        </div>
      }
      <div className={`small-tv__box ${enumToColorClass(props.color)}`} onClick={navigateTo(`${props.onClickBasePath ?? ''}/${props.id}`)}>

        <div className='small-tv__main-part'>

          <div style={{ flex: 1 }}>
            <div className='small-tv__content'>
              <picture>
                <img src={props.picture ?? '/assets/chrome_ext_course.png'} alt={props.id ?? 'course'}></img>
              </picture>
            </div>
            <h3>{props.title}</h3>
          </div>
          {
            props.hasSideButtons && <div className='small-tv__side-btns'>
              <div className='small-tv__btn small-tv__btn--dark'>
              </div>
              <div className='small-tv__btn small-tv__btn--dark'>
              </div>
              <div className='small-tv__btn small-tv__btn--dark'>
              </div>
              <div className='small-tv__btn small-tv__btn--medium small-tv__btn--semi-dark'>
              </div>
            </div>
          }
        </div>
        {
          props.hasBottomButton &&
          <div className='small-tv__bottom-btns'>
            <div className='small-tv__btn'>
            </div>
            <div className='small-tv__btn'>
            </div>
          </div>
        }
      </div>
      <div className='small-tv__icons'>
        <div className='small-tv__icons__elem'>
          <div className="star-ratings-css">
            <div className="star-ratings-css-top" style={{ width: `${(props.rating ?? 1) * 22.5}%` }}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
            <div className="star-ratings-css-bottom"><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
          </div>
          <span className="star-student-number">{props.studentNr}</span>
        </div>
        <div className="star-ratings-actions">
          {AuthService.isLoggedIn() &&
            <div className='small-tv__icons__elem clickable' onClick={() => props.toggleFavourites(props.id)}>
              {props.favourite !== undefined && (!!props.favourite == true ? <FaHeart id='heart' /> : <FaRegHeart id='heart' />)}
            </div>
          }
          {!AuthService.hasRole(['teacher']) && !props.purchased &&
            <div className='small-tv__icons__elem clickable'> <FaShoppingCart id='cart' onClick={redirectToCheckout} /> </div>
          }
        </div>
      </div>

    </div>
  );
}

export default SmallTv;
