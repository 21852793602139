import { useState } from "react";
import { Category } from "../../models/course-data/courseData.model";
import './create-category.scss';
import CategorySelect from "../../components/category-select/category-select";
import { useCreateCategory } from "../../hooks/use-categories";
import { createSlug } from "../../utils/functions";
import { ThemeType } from "../../enums/theme-type.enum";
import { useTheme } from "../../hooks/use-theme";
import { TextField, Button } from "@mui/material";

function CreateCategory() {

  const [parentCategory, setParentCategory] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [idEditedManually, setIdEditedManually] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { mutate } = useCreateCategory();
  const { theme } = useTheme();

  const handleNameChange = (event: any) => {
    let name = event.target.value;
    setName(name);
    if (!idEditedManually || !id) {
      const slug = createSlug(name);
      setId(slug);
    }
  }

  const handleIdChange = (event: any) => {
    setId(event.target.value);
    if (event.target.value == '') {
      setIdEditedManually(false);
    } else {
      setIdEditedManually(true);
    }
  }

  const handleCategoryChange = (event: any) => {
    setParentCategory(event.target.value);
  }

  const createCategory = (event: any) => {
    setSubmitted(true);

    if (!id || !name) {
      console.log("Complete the necessary fields");
      return;
    }
    const category: Category = { id, name };
    if (parentCategory != '') {
      category.parent = parentCategory;
    }
    mutate(category);

  }

  return (
    <div className={`category-create ${theme === ThemeType.DARK ? 'category-create--dark' : 'category-create--light'}`}>
      <h2>Create a new category</h2>
      <div className="category-create__input">
        <TextField
          className="input-elem"
          id="outlined-textarea"
          label="Category name"
          color="secondary"
          placeholder="Name"
          name="name"
          value={name}
          variant="outlined"
          onChange={handleNameChange}
          error={submitted && !name}
        />
      </div>
      <div className="category-create__input">
        <TextField
          className="input-elem"
          id="id"
          label="Category id"
          color="secondary"
          variant="outlined"
          placeholder="ID..."
          name="id"
          value={id}
          onChange={handleIdChange}
          error={submitted && !id}
        />
      </div>
      <CategorySelect selectedCategory={parentCategory}
        handleCategoryChange={handleCategoryChange}
        submitted={submitted}
        label='Parent category' />
      <Button variant="contained" onClick={createCategory}>Create</Button>

    </div>
  );
}

export default CreateCategory;
