import { useQuery } from "react-query";
import HttpService from '../services/HttpService.js';
const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

export const useQuestions = (courseId: string) => {
  return useQuery(['questions', courseId], () => {
    const resp = axios.get(`${baseApiUrl}/courses/${courseId}/questions`);
    return resp;
  });
}

export const createQuestion = async (courseId: string, title: string, description: string) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/questions`, { title, description });
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteQuestion = async (courseId: string, questionId: number) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/courses/${courseId}/questions/${questionId}`);
    if (response.status == 204) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const deleteAnswer = async (courseId: string, questionId: number, answerId: number) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/courses/${courseId}/questions/${questionId}/responses/${answerId}`);
    if (response.status == 204) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const updateQuestion = async (courseId: string, question: any) => {
  try {
    const { title, description } = question;
    const questionUpdate: any = {};
    if (title) {
      questionUpdate['title'] = title;
    }
    if (description) {
      questionUpdate['description'] = description;
    }
    const response = await axios.patch(`${baseApiUrl}/courses/${courseId}/questions/${question.id}`, questionUpdate);
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateAnswer = async (courseId: string, questionId: number, answerId: number, description: string) => {
  try {
    const response = await axios.patch(`${baseApiUrl}/courses/${courseId}/questions/${questionId}/responses/${answerId}`, {description});
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createAnswer = async (courseId: string, questionId: number, description: string) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/questions/${questionId}`, { description });
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};