import userIcon from '../../assets/user.png';

export class ConversationPartnerProps {
  active: boolean = false;
  status: string = '';
  id: string = '';
  name: string = '';
  picture: string = '';
  selectPartner!: (a: string) => void;

}
const ConversationPartner = (props: ConversationPartnerProps) => {
    const picture = props.picture || userIcon;
    const handleClick = () => {
        props.selectPartner(props.id)
    }
    return (
        <li className={`contact ${props.active ? 'active' : ''}`} onClick={handleClick}>
            <div className="wrap">
                <span className={`contact-status ${props.status}`}></span>
                <img src={picture} alt="" />
                <div className="meta">
                    <p className="name">{props.name}</p>
                </div>
            </div>
        </li>
    );
};

export default ConversationPartner;