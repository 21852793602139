import { TvColor } from "../components/small-tv/small-tv";

export function createSlug(str: string): string {
    return str.replace(/ +(?= )/g, '')
        .replace(/[^ a-z0-9]/gi, '')
        .trim()
        .split(' ')
        .join('-')
        .toLowerCase();
}

export function enumToColorClass(tvColor: TvColor | undefined): string {
    switch (tvColor) {
        case TvColor.GREY:
            return `light-grey-bg`;
        case TvColor.BROWN:
            return `brown-bg`;
        default:
            return `light-grey-bg`;
    }
}

export function getCentPart(nr: number): string {
    let cent = 0;
    const intPart = Math.floor(nr);
    cent = Math.floor((nr - intPart) * 100);
    return cent < 10 ? `0${cent}` : `${cent}`;
}

export function getIntPart(nr: number): number {
    return Math.floor(nr);
}

export const getRandomBoolean = () => {
    const rndInt = Math.floor(Math.random() * 6)
    return rndInt % 2 === 0;
}