import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/theme-context';
import { ThemeType } from '../../enums/theme-type.enum';
import { useTheme } from '../../hooks/use-theme';
import './nav-bar.scss';
import { FaSun } from 'react-icons/fa';
import { YellowSunProvider } from '../../providers/yellow-sun.provider.tsx/yellow-sun.provider';
import { YellowMoonProvider } from '../../providers/yellow-moon.provider.tsx/yellow-moon.provider';
import { Link, useNavigate } from 'react-router-dom';
import { HiMenu } from 'react-icons/hi';
import { Breakpoints } from '../../enums/breakpoints';
import useWindowDimensions from '../../hooks/use-window-dimensions';
import RenderOnGuest from '../../utils/RenderOnGuest';
import RenderOnLoggedIn from '../../utils/RenderOnLoggedIn';
import AuthService from '../../services/AuthService';
import RenderOnRoles from '../../utils/RenderOnRoles';

function NavBar
  () {
  // const {theme, changeTheme} = useContext(ThemeContext);;
  const { theme, changeTheme } = useTheme();
  const [themeIcon, setThemeIcon] = useState(theme);
  const [isVisible, setIsVisible] = useState(true);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0); // TODO: something really strange is happening here 
  let scrollPos = 0;
  const { width, height } = useWindowDimensions();

  let navigate = useNavigate();  // CANNOT use HERE, 
  const navigateTo = (path: string) => () => navigate(path); // path ex '/courses'
  // eg.history.push('/login');


  const toggleTheme = () => {
    setThemeIcon(themeIcon => themeIcon === ThemeType.DARK ? ThemeType.LIGHT : ThemeType.DARK)
    changeTheme();

  }

  useEffect(() => {
    // setScrollPosition(100);

    window.addEventListener('scroll', listenToScroll);
    return () =>
      window.removeEventListener('scroll', listenToScroll);
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 40;
    let winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;

    if (winScroll - scrollPos > heightToHideFrom) {
      // isVisible &&      // to limit setting state only the first time    
      // setScrollPosition(100);
      scrollPos = winScroll;

      setIsVisible(false);
    } else if (scrollPos - winScroll > heightToHideFrom) {
      // !isVisible && 
      // setScrollPosition(100);
      scrollPos = winScroll;

      setIsVisible(true);
    }

    // console.log(888, winScroll, scrollPosition);

  };

  const toggleDropDown = () => {
    setIsDropDownVisible(!isDropDownVisible);
  }

  return (
    <>
      {
        isVisible
        &&
        <div className='nav-bar'>
          <div className='nav-bar__left'>
            {/* <Link to={''} >
            </Link> */}
            <div className='logo' onClick={navigateTo('/')}>CourseBook</div>
          </div>
          {width < Breakpoints.LAPTOP && <div className='nav-bar__clickable' onClick={toggleTheme}>
            {themeIcon === ThemeType.DARK ?
              <YellowSunProvider></YellowSunProvider> :
              <YellowMoonProvider></YellowMoonProvider>}
          </div>}
          {width < Breakpoints.LAPTOP && <div className='menu-icon nav-bar__clickable' onClick={toggleDropDown}>
            <HiMenu height={'3em'} width={'3em'} /></div>}
          {(width > Breakpoints.LAPTOP || isDropDownVisible) && <div className={`nav-bar__right ${width < Breakpoints.LAPTOP && 'nav-bar__right--drop-down'}`}>
            <div className='nav-bar__elem nav-bar__clickable' onClick={navigateTo('/courses')}>
              Courses
            </div>
            {/* <div className='nav-bar__elem nav-bar__clickable'>
              Be PRO
            </div> */}
            {width > Breakpoints.LAPTOP && <div className='nav-bar__clickable' onClick={toggleTheme}>
              {themeIcon === ThemeType.DARK ?
                <YellowSunProvider></YellowSunProvider> :
                <YellowMoonProvider></YellowMoonProvider>}
            </div>}

            <RenderOnGuest>
              <div className='nav-bar__elem nav-bar__clickable' onClick={() => AuthService.doLogin()}>
                Login
              </div>
            </RenderOnGuest>

            <RenderOnLoggedIn>
              <div className='nav-bar__elem nav-bar__clickable' onClick={navigateTo('/profile/user')}>
                Profile
              </div>
              <RenderOnRoles roles={['teacher']}>
                <div className='nav-bar__elem nav-bar__clickable' onClick={navigateTo('/teaching/my-courses')}>
                  Teaching
                </div>
              </RenderOnRoles>
              <div className='nav-bar__elem nav-bar__clickable' onClick={navigateTo('/chat')}>
                Chat
              </div>
              <div className='nav-bar__elem nav-bar__clickable' onClick={() => AuthService.doLogout()}>
                Log out
              </div>
            </RenderOnLoggedIn>

          </div>
          }
          {/* <div>
            
          </div> */}
        </div>
      }
    </>
  );
}

export default NavBar;
