import { Category } from "../../models/course-data/courseData.model";
import { useCategories } from "../../hooks/use-categories";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

class CategorySelectProps {
  selectedCategory: string = '';
  handleCategoryChange: any;
  submitted: boolean = false;
  required?: boolean = false;
  label?: string = 'Category';
}

export default function CategorySelect(props: CategorySelectProps) {
  const { isLoading, isError, data, error } = useCategories();


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>{(error as Error).message}</div>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="categories-select" className="input-label" variant="standard">{props.label ?? 'Category'}</InputLabel>
      <Select
        labelId="categories-select"
        id="categories-select"
        value={props.selectedCategory}
        name="categories"
        label={props.label ?? 'Category'}
        onChange={props.handleCategoryChange}
        error={props.required && props.submitted && !props.selectedCategory}
      >
        {
          data.map((category: Category) =>
            <MenuItem value={category.id} key={category.id} >
              {category.name}
            </MenuItem>
          )
        }
      </Select>
    </FormControl>);
}
