import { useQuery } from "react-query";
import HttpService from '../services/HttpService.js';
const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

export const useMyBadges = () => {
  return useQuery(['badges'], () => {
    const resp = axios.get(`${baseApiUrl}/profile/badges`);
    return resp;
  });
}