import React from 'react';
import logo from './logo.svg';
import './footer.scss';
import { useTheme } from '../../hooks/use-theme';
import { ThemeType } from '../../enums/theme-type.enum';

function Footer
() {
  const { theme, changeTheme } = useTheme();

  return (
    <div className={`footer ${theme === ThemeType.LIGHT ? 'footer--light': 'footer--dark'}`}>
      {/* <p>Copyright @ Pamacs</p> */}
      <p>Follow us on</p>
      <div className='footer__social-media'>
      <img className='footer__social-media__elem' src="/assets/YouTube-Icon.svg" alt=""></img>
      <img className='footer__social-media__elem' src="/assets/Facebook.svg" alt=""></img>
      <img className='footer__social-media__elem' src="/assets/Instagram-Logo.svg" alt=""></img>
      </div>
      <div className='footer__apps'>
      <img className='footer__apps__elem' src="/assets/Google_Play.svg" alt="google_play"></img>
      <img className='footer__apps__elem' src="/assets/App_Store.svg" alt="app_store"></img>
      </div>
    </div>
  );
}

export default Footer;
