import './not-found.scss'


function NotFound() {

  return (
    <div className='not-found'>
      <div>404 - NOT FOUND</div>
      
    </div>
  );
}

export default NotFound;
