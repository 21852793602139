import { marked } from "marked";
import { escape, unescape } from "lodash";

const block = (text: string) => text + "\n\n";
const escapeBlock = (text: string) => escape(text) + "\n\n";
const line = (text: string) => text + "\n";
const inline = (text: string) => text;
const newline = () => "\n";
const empty = () => "";

const TxtRenderer: marked.Renderer = {
	// Block elements
	code: escapeBlock,
	blockquote: block,
	html: empty,
	heading: block,
	hr: newline,
	list: (text) => block(text.trim()),
	listitem: line,
	checkbox: empty,
	paragraph: block,
	table: (header, body) => line(header + body),
	tablerow: (text) => line(text.trim()),
	tablecell: (text) => text + " ",
	// Inline elements
	strong: inline,
	em: inline,
	codespan: inline,
	br: newline,
	del: inline,
	link: (_0, _1, text) => text,
	image: (_0, _1, text) => text,
	text: inline,
	// etc.
	options: {},
};

export function markdownToTxt(
	markdown: string,
	options?: marked.MarkedOptions
): string {
	const unmarked = marked(markdown, { ...options, renderer: TxtRenderer });
	const unescaped = unescape(unmarked);
	const trimmed = unescaped.trim();
	return trimmed;
}
