// import { Box, TabContext } from '@mui/material';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Gravatar from 'react-gravatar';

import './profile.scss';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';

// LinearProgressWithLabel.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate and buffer variants.
//    * Value between 0 and 100.
//    */
//   value: PropTypes.number.isRequired,
// };

function Profile() {
  const [value, setValue] = React.useState('1');
  const [progress, setProgress] = React.useState(10);
  const { pathname } = useLocation();

  let navigate = useNavigate();
  const navigateTo = (path: string) => {
    return () => navigate(path)
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    // console.log("pathName", pathname);
  };

  useEffect(() => {
    if (pathname === "/profile/user") {
      setValue('1');
    } else if (pathname === "/profile/my-courses") {
      setValue('2');
    } else if (pathname === "/profile/progress") {
      setValue('3');
    } else if (pathname === "/profile/history") {
      setValue('4');
    } else if (pathname === "/profile/favourite-courses") {
      setValue('5');
    } else if (pathname === "/profile/badges") {
      setValue('6');
    } else if (pathname === "/profile/quiz-results") {
      setValue('7');
    }
  }, [pathname])

  return (
    <div className="profile">
      <div className="profile__header">
        <div className="profile__header__picture">
          <Gravatar email={AuthService.getParsedToken()?.email}
            size={300}
            // @ts-ignore
            default="robohash" />
        </div>
      </div>

      <div className="profile__cont-wrapper">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="profile" centered>
                <Tab label="Home" value="1" onClick={navigateTo('user')} />
                <Tab label="My courses" value="2" onClick={navigateTo('my-courses')} />
                <Tab label="Progress" value="3" onClick={navigateTo('progress')} />
                <Tab label="History" value="4" onClick={navigateTo('history')} />
                <Tab label="Favorites" value="5" onClick={navigateTo('favourite-courses')} />
                <Tab label="Badges" value="6" onClick={navigateTo('badges')} />
                <Tab label="Quiz Results" value="7" onClick={navigateTo('quiz-results')} />
              </TabList>
            </Box>
            <TabPanel value="1">
            </TabPanel>
            <TabPanel value="2">
            </TabPanel>
            <TabPanel value="3">
            </TabPanel>
            <TabPanel value="4">
            </TabPanel>
            <TabPanel value="5">
            </TabPanel>
            <TabPanel value="6">
              {/* Some cute badges that you can win
              <div>like:</div>
              <ol>first 30 min</ol>
              <ol>first 2 hours</ol>
              <ol>first course compleated</ol>
              <div className='badge'>
                <div>
                  first 30 min
                </div>
              </div> */}
            </TabPanel>
            <TabPanel value="7">
            </TabPanel>
          </TabContext>
        </Box>
        <Outlet />

        {/* <div className="profile__cont-wrapper__content">

        </div> */}
      </div>
    </div>
  );
}

export default Profile;
