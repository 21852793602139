import Button from "@mui/material/Button";
import { Paper, Rating, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReviews, createReview } from '../../hooks/use-reviews';
import { useQueryClient } from "react-query";
import './reviews.scss';
import AuthService from "../../services/AuthService";
const dateOptions = {
  month: "long",
  day: "numeric",
  year: "numeric"
};

function Reviews() {
  const { id } = useParams();
  const { isLoading, data } = useReviews(id ?? '0');
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState('');
  const [rated, setRated] = useState(false);
  const queryClient = useQueryClient()

  useEffect(() => {
    if (data?.data) {
      const userId = AuthService.getParsedToken()?.sub;
      const result = data.data.find((rev: any) => {
        return rev.id === userId
      });
      if (result) {
        setRated(true);
      }
      // update my rating
    }
  }, [data])

  const submitReview = async () => {
    if (!id) return;
    try {
      await createReview(id, { description: review, rating });
      queryClient.invalidateQueries('reviews')
    } catch (error) {
      console.log("Error creating review", error)
    }
  }
  if (isLoading || !data) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div className="rating">
        {!rated && <>
          <h2>Rate this course</h2>
          <Rating name="review-rating" defaultValue={5}
            precision={0.5} size="large"
            onClick={(event: any) => { setRating(event.target.value) }} />
          <div className="add-edit-course__input">

            <div className="row">
              <TextField
                className="input-elem"
                id="outlined-multiline-flexible"
                label="Details"
                placeholder="Review..."
                multiline
                color="secondary"
                minRows={5}
                maxRows={50}
                name="review"
                value={review}
                onChange={(event) => { setReview(event.target.value) }}
              />
            </div>
          </div>
          <div>
            <Button variant="contained" onClick={submitReview}>Submit</Button>
          </div>
        </>}
        {data.data.map((review: any, index: number) => (
          <Paper elevation={3} key={index} className="review-list-element">
            <div className="review-list-element-title">
              <h4 className="review-list-element-title-elem">{review.name}</h4>
              <p className="review-list-element-title-elem">{new Date(review.date).toLocaleDateString("en-US", (dateOptions as any))}</p>
            </div>
            <Rating name="review-rating" value={review.rating} precision={0.5} readOnly />
            <pre>{review.description}</pre>
          </Paper>

        ))}
      </div>
    );
  }
}

export default Reviews;
