import { useEffect, useState } from 'react';
import { useQuizResults } from '../../hooks/use-quiz-results';
import './quiz-results.scss';
import { badges as badgeList } from '../../constants/constants';
import { BadgeData } from '../../models/statistics/badgeData.model';
import BagdeCard from '../../components/bagde-card/bagde-card';

function QuizResults() {
  const { isLoading, data } = useQuizResults();
  // const [badges, setBadges] = useState<BadgeData[]>([]);


  // useEffect(() => {
  //  setBadges(badgeList);
  // }, [])

  useEffect(() => {
    console.log("data", data);

  }, [data])


  if (isLoading) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div className="quiz-results">
        <h1 className="quiz-results__title">
          --- Quiz results ---
        </h1>
        <div className="quiz-results__content">
          {data?.map((quizCard, quizCardIndex) => (
            // <BagdeCard badge={badge} key={badge.id}></BagdeCard>
            <div className="quiz-results__content__quiz">
              <h2>Quiz #{quizCardIndex}</h2>
              <div className="quiz-results__content__quiz__path">{quizCard.courseTitle} {'>'} {quizCard.videoTitle}</div>

              {quizCard.quiz.map((quiz, quizIndex) => (
                <>
  
                  <div className="quiz-results__content__quiz__question">
                    {quizIndex + 1}. Question: {quiz.question}
                  </div>
                  {quiz.responses.map(response => <div className="quiz-results__content__quiz__result">
                    <div>{response.date}</div>
                    <div className={`${response.correctResponse? 'quiz-results__content__quiz__result--correct' : 'quiz-results__content__quiz__result--wrong'}`}>{response.correctResponse ? 'Correct' : 'Wrong'}</div>
                  </div>)
                  }
                </>

              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default QuizResults;
