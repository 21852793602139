import Keycloak from "keycloak-js";

const kc = Keycloak({
    "realm": "app",
    "url": process.env.REACT_APP_KEYCLOAK,
    "clientId": "frontend",    
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
//TODO Give this a timeout or something
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  kc.init({
    // onLoad: 'login-required',
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent.html`,
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      console.log(`Authenticated: ${authenticated}`);
      onAuthenticatedCallback();
    }).catch((error) => {
      console.log('Keycloak auth error: ');   
      console.log(error);

      onAuthenticatedCallback();
    });
};

const doLogin = kc.login;

const doLogout = kc.logout;

const getToken = () => kc.token;

const isLoggedIn = () => !!kc.token;

const updateToken = (successCallback: any) =>
  kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getParsedToken = () => kc.tokenParsed;

const hasRole = (roles: string[]) => {
  try {
    return roles.some((role :string) => kc.hasRealmRole(role) || kc.hasResourceRole(role));
  } catch (error) {
    console.error(error);
  }
}
const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getParsedToken,
  hasRole,
};

export default AuthService;
