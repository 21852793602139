import { TextField, Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import Article from '../../components/article/article';
import Filters from '../../components/filters/filters';
import SmallTv, { TvColor } from '../../components/small-tv/small-tv';
import { useCourses } from '../../hooks/use-courses';
import { useQueryClient } from "react-query";
import useWindowDimensions from '../../hooks/use-window-dimensions';
import './courses.scss';
import FavouritesService from '../../services/FavouritesService';
import { getRandomBoolean } from '../../utils/functions';

function Courses() {
  const [chunkSize, setChunkSize] = useState(2);
  const [rowData, setRowData] = useState<any[]>([]);
  const { width, height } = useWindowDimensions();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('')
  const { isLoading, data } = useCourses(filter);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [priceInterval, setPriceInterval] = useState([0, 100]);
  const [rateInterval, setRateInterval] = useState([0, 5]);
  const queryClient = useQueryClient()
  const [randomArray, setRandomArray] = useState<boolean[]>([]);
  const randomArraySize = 100;

  useEffect(() => {
    const randArr: boolean[] = [];
    for (let i = 0; i < randomArraySize; i++) {
      randArr.push(getRandomBoolean());
    }
    setRandomArray(randArr);

    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccessMessage("Course purchased successfully!");
    }
    if (query.get("canceled")) {
      setErrorMessage(
        "Order cancelled."
      );
    }
  }, [])

  let inputHandler = (e: any) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };
  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (successMessage) setSuccessMessage('');
    if (errorMessage) setErrorMessage('');
  };

  const updateFilter = () => {
    let newFilter = '';
    if (search) {
      newFilter += `&search=${search}`;
    }
    if (priceInterval) {
      newFilter += `&minprice=${priceInterval[0]}&maxprice=${priceInterval[1]}`;
    }
    if (rateInterval) {
      newFilter += `&minrating=${rateInterval[0]}&maxrating=${rateInterval[1]}`;
    }
    // Add categories, price and rating as well
    if (newFilter) {
      setFilter(newFilter.slice(1));
    } else {
      setFilter('');
    }
  }

  // TODO: kepernyo meret konstans hatarok definialasa
  useEffect(() => {
    // console.log("inner width", width);
    setChunkSize(width < 768 ? 1 : (width < 1200 ? 2 : 3));
  }, [width])

  // This will not send a new request on every keystroke or filter change to the server
  // Instead, only when the user has not been typing/changing filters for 800ms
  useEffect(() => {
    const delayFunction = setTimeout(() => {
      updateFilter();
    }, 800)
    return () => clearTimeout(delayFunction)
  }, [search, priceInterval, rateInterval])

  useEffect(() => {
    if (data) {
      const newData: any[] = [];
      for (let i = 0; i < data.data.length; i += chunkSize) {
        const chunk = data.data.slice(i, i + chunkSize);
        newData.push(chunk);
      }
      setRowData(newData);
    }

  }, [chunkSize, data])

  const toggleFavourites = async (courseId: string) => {
    try {
      if (!courseId) return;
      await FavouritesService.toggleFavourites(courseId);
      queryClient.invalidateQueries('courses')
    } catch (error) {
      console.log("Error changing favourites", error)
    }
  }

  return (
    <div className="courses">
      <div className="courses__filter-wrapper">
        <Filters
          priceInterval={priceInterval}
          setPriceInterval={setPriceInterval}
          rateInterval={rateInterval}
          setRateInterval={setRateInterval}
        />
      </div>
      <div className='courses__content'>
        {/* <div className='search-bar'> */}
        {/* <ReactSearchBox
          placeholder="Placeholder"
          leftIcon={<FaSearch className='search-icon'/>}
          // value="Doe"
          data={dataS}
           onSelect={function (record: any): void {
             console.log("on select");             
          } } 
          onChange={function (value: string): void {
            console.log("on change");
          } }        // callback={(record:any) => console.log(record)}
      /> */}
        <div className="search-bar">
          <TextField
            id="outlined-basic"
            onChange={inputHandler}
            variant="outlined"
            fullWidth
            label="Search"
          />
        </div>
        <Snackbar open={successMessage != ''} autoHideDuration={5000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        <Snackbar open={errorMessage != ''} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        {isLoading && <p>Loading...</p>}
        {!isLoading &&
          rowData && rowData.map((row: any[], index) => {
            // Not nice key=index
            return <div className='shelf-wrapper' key={index}>
              <div className='shelf-wrapper__content'> {
                row.map((course: any, index: number) => {
                  return (
                    <div key={course.id}>
                      {
                        course.type === 'video' &&
                        <SmallTv
                          id={course.id}
                          title={course.title}
                          picture={course.picture}
                          price={course.price}
                          rating={course.rating}
                          studentNr={course.studentNr}
                          favourite={course.favourite}
                          purchased={course.purchased}
                          hasSideButtons={randomArray[index % randomArraySize]}
                          hasBottomButton={randomArray[(index + 1) % randomArraySize]}
                          toggleFavourites={toggleFavourites}
                          onClickBasePath='/courses'
                          color={randomArray[(index + 2) % randomArraySize] ? TvColor.BROWN : TvColor.GREY}

                        />}

                      {
                        course.type === 'article' &&
                        <Article title={course.title}
                          picture={course.picture}
                          price={course.price}
                          hasSideButtons={getRandomBoolean()}
                          hasBottomButton={getRandomBoolean()}
                          color={getRandomBoolean() ? TvColor.BROWN : TvColor.GREY} />
                      }
                    </div>
                  )
                })}
              </div>
              <div className='shelf'>
                <div className='shelf__wood'>
                </div>
                <div className='shelf__wood-front'>
                </div>
              </div>
            </div>

          })
        }


        {/* <div className='shelf-wrapper'>
          <div className='shelf-wrapper__content'>
            <SmallTv hasSideButtons={false} hasBottomButton={true}></SmallTv>
            <SmallTv hasSideButtons={false} hasBottomButton={true}></SmallTv>
          </div>
          <div className='shelf'>
            <div className='shelf__wood'>
            </div>
            <div className='shelf__wood-front'>
            </div>
          </div>
        </div>

        <div className='shelf-wrapper'>
          <div className='shelf-wrapper__content'>
            <SmallTv hasSideButtons={false} hasBottomButton={true}></SmallTv>
            <SmallTv hasSideButtons={false} hasBottomButton={true}></SmallTv>
          </div>
          <div className='shelf'>
            <div className='shelf__wood'>
            </div>
            <div className='shelf__wood-front'>
            </div>
          </div>
        </div> */}
        {/* <div className='shelf'>
          <div className='shelf__wood'></div>
          <div className='shelf__wood-front'></div>
        </div>
        <div className='shelf'>
          <div className='shelf__wood'></div>
          <div className='shelf__wood-front'></div>
        </div> */}

      </div>
    </div>
  );
}

export default Courses;
