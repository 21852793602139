import './statistics.scss';
import { useState } from 'react';
import { usePurchases, useViewsTeacher } from '../../hooks/use-progress';
import Chart from '../chart/chart';

function Statistics() {
  const [nrOfDaysView, setNrOfDaysView] = useState(7);
  const [nrOfDaysSubs, setNrOfDaysSubs] = useState(7);
  const views = useViewsTeacher(nrOfDaysView);
  const purchases = usePurchases(nrOfDaysSubs);

  return (
    <div>
      <h2>Watch history</h2>
      <Chart data={views.data} days={nrOfDaysView} setDays={setNrOfDaysView} isLoading={views.isLoading} />
      <h2>Latest purchases</h2>
      <Chart data={purchases.data} days={nrOfDaysSubs} setDays={setNrOfDaysSubs} isLoading={purchases.isLoading} />
    </div>
  );
}


export default Statistics;
