import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './header.scss';
import Button, { TextChunks } from '../button/button';
import { text } from 'stream/consumers';
import { Link, useNavigate } from 'react-router-dom';
import AnimatedBook from '../animated-book/animated-book';
import useWindowDimensions from '../../hooks/use-window-dimensions';

function Header
  () {
    const [bookOpened, setBookOpened] = useState(false);
    let navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const [coursesText, setCoursesText] = useState<TextChunks[]>([]); 
    const [proText, setProText] = useState<TextChunks[]>([]); 

    const navigateTo = (path: string) => () => navigate(path);
    const openBook = () => {
      setBookOpened(true);
    }
    const closeBook = () => {
      setBookOpened(false);
    }

    useEffect(() => {
      setCoursesText(width > 768 ?
         [{ text: 'Go to ', uppercase: false, bold: false }, { text: 'Courses', uppercase: true, bold: true }]
         :
         [{ text: 'Courses', uppercase: true, bold: true }]
         )

    }, [width]);

  return (
    <div className="header">
      {/* im a header */}
      {bookOpened && <div onClick={closeBook}>
        <AnimatedBook></AnimatedBook>
        </div> }

      <div className='header__light'>

      </div>
      <div className='header__content'>
        <div className='header__content__left-side'>
          <h1>The greatest place to learn software development</h1>
          <div className='header__content__left-side__secondary-text'>
          <p>Explore our top quality courses to learn about Web development, DevOps, Security and more</p>
          </div>
          <div className='header__content__buttons'>
            <Button onClick={navigateTo('/courses')} textConfig={coursesText}></Button>
            {/* <Button onClick={navigateTo('/pro')} textConfig={[{ text: 'Be ', uppercase: false, bold: false }, { text: 'PRO', uppercase: true, bold: true }]}></Button> */}
          </div>
        </div>
        <div className='header__content__illustration'>
        <img className='header__content__illustration__stairs' src="/assets/stairs2.svg" alt="stairs"></img>
        <img className='header__content__illustration__img' src="/assets/krisztian.svg" alt="krisztian"></img>
        <img className='header__content__illustration__book' onClick={openBook} src="/assets/konyv.svg" alt="konyv"></img>
        </div>
      </div>
    </div>
  );
}

export default Header;
