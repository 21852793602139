import { AddEditFormType } from "../../enums/add-edit-form-type.enum";
import { CourseType } from "../../enums/course-type.enum";
import AddEditCourse from "./add-edit-course/add-edit-course";
import './course-upload.scss';

function CourseUpload() {
  return (
    <div className='course-upload'>
      <h2>Create a new course</h2>
      <AddEditCourse type={AddEditFormType.ADD} courseType={CourseType.VIDEO}></AddEditCourse>
    </div>
  );
}

export default CourseUpload;
