import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './providers/theme/theme.provider';
import HttpService from "./services/HttpService";
import AuthService from "./services/AuthService";
import { withStyles } from '@material-ui/core';

const styles = (theme: any) => ({
  "@global": {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      fontSize: 12,
      [theme.breakpoints.up("tablet")]: {
        fontSize: 14,
        textarea: {
          fontSize: 16,
        },
        input: {
          fontSize: 16,
        }
      },
      [theme.breakpoints.up("laptop")]: {
        fontSize: 14,
        textarea: {
          fontSize: 16,
        },
        input: {
          fontSize: 16,
        }
      },
      [theme.breakpoints.up("desktop")]: {
        fontSize: 16,
        textarea: {
          fontSize: 18,
        },
        input: {
          fontSize: 18,
        }
      }
    }
  }
});

const StyledApp = withStyles(styles)(App);

const renderApp = () => ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider>
        <StyledApp />
      </ThemeProvider>

    {/* , */}

  </React.StrictMode>,
  document.getElementById('root')
);
renderApp()
AuthService.initKeycloak(renderApp);
HttpService.configure();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
