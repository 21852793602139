import { useState } from "react";
import './marketing.scss';
import { ThemeType } from "../../enums/theme-type.enum";
import { useTheme } from "../../hooks/use-theme";
import { TextField, Button } from "@mui/material";
import { markdownExample } from "../../utils/constants";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Alert, Snackbar } from "@mui/material";
import showdown from 'showdown';
import { markdownToTxt } from "../../utils/markdown-to-txt";
import HttpService from "../../services/HttpService";
function Marketing() {

  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { theme } = useTheme();
  const axios = HttpService.getAxiosClient();
  const baseApiUrl = `${process.env.REACT_APP_MARKETING_HOST}/api`;

  const handleSubjectChange = (event: any) => {
    let subject = event.target.value;
    setSubject(subject);
  }

  const handleBodyChange = (event: any) => {
    let body = event.target.value;
    setBody(body);
  }

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (successMessage) setSuccessMessage('');
    if (errorMessage) setErrorMessage('');
  };

  const sendMail = async (event: any) => {
    setSubmitted(true);
    if (!body || !subject) {
      setErrorMessage("Complete the necessary fields");
      return;
    }
    const converter = new showdown.Converter();
    const html = converter.makeHtml(body);
    const text = markdownToTxt(body);

    try {
      const response = await axios.post(`${baseApiUrl}/notifications/mail`, { subject, text, html });
      if (response.status == 200) {
        setSuccessMessage('E-mail sent successfully.')
        setSubject('');
        setBody('');
      } else {
        throw new Error("Error");
      }
    } catch (error: any) {
      setErrorMessage('Error sending e-mail. Try again later.');
      console.log(`Error sending mail: ${error.message}`);
      return null;
    }
  }

  const fillMarkdownExample = () => {
    setBody(markdownExample);
  }
  return (
    <div className={`marketing ${theme === ThemeType.DARK ? 'marketing--dark' : 'marketing--light'}`}>
      <h2>Send marketing e-mail to students</h2>
      <div className="marketing__input">
        <TextField
          className="input-elem"
          id="outlined-textarea"
          label="Subject"
          color="secondary"
          placeholder="Subject"
          name="subject"
          value={subject}
          variant="outlined"
          onChange={handleSubjectChange}
          error={submitted && !subject}
        />
      </div>

      <div className="marketing__input">
        <h3>E-mail body</h3>
        <p>This is a <a href="https://www.markdownguide.org/basic-syntax/" target='_blank'>markdown</a> editor. The preview will appear on the side.
          <span className="small-btn" onClick={fillMarkdownExample}>Fill it with an EXAMPLE</span></p>

        <div className="row">
          <TextField
            className="input-elem"
            id="outlined-multiline-flexible"
            label="Details"
            placeholder="Body..."
            multiline
            color="secondary"
            minRows={15}
            maxRows={100}
            name="body"
            value={body}
            onChange={handleBodyChange}
          />
          <div className="previewer input-elem">
            <ReactMarkdown children={body} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
      </div>
      <Button variant="contained" onClick={sendMail}>Send</Button>
      <Snackbar open={successMessage != ''} autoHideDuration={5000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={errorMessage != ''} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

    </div>
  );
}

export default Marketing;
