export const badges = [{
    id: 'watch5',
    title: 'Watch 5 videos',
    description: 'Watch your first 5 videos to get this badge.',
    achieved: false,
    image: 'badge_tmp2.svg'
}, {
    id: 'watch25',
    title: 'Watch 25 videos',
    description: 'Watch your first 25 videos to get this badge.',
    achieved: false,
    image: 'badge_tmp2--blue.svg'
}, {
    id: 'watch50',
    title: 'Watch 50 videos',
    description: 'Watch your first 50 videos to get this badge.',
    achieved: false,
    image: 'badge_tmp2--green.svg'
}, {
    id: 'watch100',
    title: 'Watch 100 videos',
    description: 'Watch your first 100 videos to get this badge.',
    achieved: false,
    image: 'badge_tmp2--orange.svg'
}, {
    id: 'review1',
    title: 'Review a course',
    description: 'Leave your first review for a course you have taken.',
    achieved: false,
    image: 'badge_tmp2--pink.svg'
}, {
    id: 'question1',
    title: 'Curious person',
    description: 'Ask your first question in the Q&A section.',
    achieved: false,
    image: 'badge_tmp2--purple.svg'
}, {
    id: 'favourite1',
    title: 'I like this',
    description: 'Add your first course to your favourites to be able to find it easier.',
    achieved: false,
    image: 'badge_tmp2--red.svg'
}, {
    id: 'course1',
    title: 'First steps',
    description: 'Purchase your first course and start learning.',
    achieved: false,
    image: 'badge_tmp2--yellow.svg'
}, {
    id: 'course2',
    title: 'Continuous learner',
    description: 'Purchase your second course and continue your learning journey.',
    achieved: false,
    image: 'badge_tmp2.svg'
}, {
    id: 'course3',
    title: 'Experienced student',
    description: 'Purchase your third course and continue your learning journey.',
    achieved: false,
    image: 'badge_tmp2--green.svg'
}, {
    id: 'course5',
    title: 'Semi professional',
    description: 'Purchase your fifth course and gain more and more skills.',
    achieved: false,
    image: 'badge_tmp2--orange.svg'
}, {
    id: 'course10',
    title: 'Software Engineer',
    description: 'Purchase your tenth course and master multiple fields.',
    achieved: false,
    image: 'badge_tmp2--pink.svg'
},];



export { }