import { title } from 'process';
import { RiCentosLine } from 'react-icons/ri';
import { ThemeContext } from '../../context/theme-context';
import { useTheme } from '../../hooks/use-theme';
import './article.scss';

export enum TvColor {
  BROWN,
  GREY
}
/*
  "title": "Chrome extensions Masterclass 1",
  "price": 23.82,
  "rating": 4.8,
  "picture": "https://miro.medium.com/max/1400/1*1HiMZ1JFtQ2vaXL9z8dkDg.jpeg",
  "favourite": true,
  "purchased": false,
*/
class ArticleProps {
  title: string = '';
  price?: number = 0;
  rating?: number = 5; // nem biztos h mindegyikre szavazott mar vlki
  picture: string = ''; // TODO: set default picture
  favourite?: boolean = false;
  purchased?: boolean = false;

  hasSideButtons?: boolean = false;
  hasBottomButton?: boolean = false;
  color?: TvColor = TvColor.GREY;
}

// TODO: altalanositani => small tv
function Article
  (articleProps: ArticleProps) {
  // const bla = useTheme();
  function enumToColorClass(tvColor: TvColor | undefined): string {
    switch (tvColor) {
      case TvColor.GREY: // TODO: check the dark/light mode
        return `light-grey-bg`;
      case TvColor.BROWN:
        return `brown-bg`;
      default:
        return `light-grey-bg`;
    }
  }

  function getCentPart(nr: number): string {
    let cent = 0;
    const intPart = Math.floor(nr);
    cent = Math.floor((nr - intPart) * 100);
    // console.log("cent", cent);

    return cent < 10 ? `0${cent}` : `${cent}`;
  }

  function getIntPart(nr: number): number {
    return Math.floor(nr);
  }

  return (
    <ThemeContext.Consumer>
      {({ theme, changeTheme }) => (
        <div className='article'>
          {/* {!articleProps.purchased && articleProps.price !== undefined &&
            <div className='article__price'>
              <div className='article__price__text'>
                $
                {getIntPart(articleProps.price)}
                <sup>{getCentPart(articleProps.price)}</sup>

              </div>
            </div>
          } */}
          <div className={`article__box ${enumToColorClass(articleProps.color)}`}>
            <div className='article__main-part'>
              <div style={{ flex: 1 }}>
                <div className='article__content'>
                  <picture>
                    <img src='/assets/chrome_ext_course.png' alt='course'></img>
                  </picture>
                </div>
                <h3>{articleProps.title}</h3>
              </div>
            </div>
            <div className='article__type'>
              Article
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

export default Article;
function className(arg0: {}) {
  throw new Error('Function not implemented.');
}

