import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/nav-bar/nav-bar';
import CourseWatching from './pages/course-watching/CourseWatching';
import LandingPage from './pages/landing-page/landing-page';
import Footer from './components/footer/footer';
import Chat from './components/chat/chat';
import { ThemeType } from './enums/theme-type.enum';
import { useTheme } from './hooks/use-theme';
import Courses from './pages/courses/courses';
import { QueryClient, QueryClientProvider } from 'react-query';
import Profile from './pages/profile/profile';
import { PrivateRoute } from './utils/PrivateRoute';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactQueryDevtools } from 'react-query/devtools'
import CourseUpload from './pages/course-upload/course-upload';
import NotFound from './pages/not-found/not-found';
import CourseEdit from './pages/course-edit/course-edit';
import ErrorBoundary from './utils/ErrorBoundary';
import CreateCategory from './pages/create-category/create-category';
import CoursesTeaching from './pages/courses-teaching/courses-teaching';
import UserProfile from './pages/user-profile/user-profile';
import Teaching from './pages/teaching/teaching';
import MyCourses from './pages/my-courses/my-courses';
import FavouriteCourses from './pages/favourite-courses/favourite-courses';
import Progress from './components/progress/progress';
import History from './components/history/history';
import MyBadges from './pages/badges-page/my-badges';
import Marketing from './pages/marketing/marketing';
import Statistics from './components/statistics/statistics';
import QuizResults from './pages/quiz-results/quiz-results';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}
const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 30000,
      },
    }
  });



function App() {
  const { theme } = useTheme();

  const muiTheme = createTheme({
    palette: {
      // @ts-ignore
      mode: theme.toString(),
      primary: {
        main: '#EB3474'
      },
      secondary: {
        main: '#14F7DD'
      }
    },
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        laptop: 992,
        desktop: 1200,
      },
    },
    // root: {
    //   [theme.breakpoints.up('md')]: {

    //   }
    // }
  });
  return (

    // <ThemeProvider>
    /* // <ThemeContext.Provider value={{theme: theme, changeTheme: changeTheme}}> */
    <ThemeProvider theme={muiTheme}>

      <QueryClientProvider client={queryClient}>
        <div className={`main-parts ${theme === ThemeType.LIGHT ? 'light-theme' : 'dark-theme'}`} >

          <BrowserRouter>
            {/* <App /> */}
            <NavBar></NavBar>
            <div className='content primary-bg'>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                {/* <Route path="/" element={<App />} /> */}
                <Route path="courses" element={<Courses />} />
                <Route path="courses/:id" element={<CourseWatching />} />

                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>}>
                  <Route path="user" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                  <Route path="my-courses" element={<PrivateRoute><MyCourses /></PrivateRoute>} />
                  <Route path="progress" element={<PrivateRoute><Progress /></PrivateRoute>} />
                  <Route path="history" element={<PrivateRoute><History /></PrivateRoute>} />
                  <Route path="favourite-courses" element={<PrivateRoute><FavouriteCourses /></PrivateRoute>} />
                  <Route path="badges" element={<PrivateRoute><MyBadges /></PrivateRoute>} />
                  <Route path="quiz-results" element={<PrivateRoute><QuizResults /></PrivateRoute>} />
                </Route>

                <Route path="/teaching" element={<PrivateRoute><Teaching /></PrivateRoute>}>
                  <Route path="my-courses" element={<PrivateRoute><CoursesTeaching /></PrivateRoute>} />
                  <Route path="create-category" element={<PrivateRoute><CreateCategory /></PrivateRoute>}/>
                  <Route path="edit-course/:id" element={<PrivateRoute><CourseEdit /></PrivateRoute>} />
                  <Route path="marketing" element={<PrivateRoute><Marketing /></PrivateRoute>} />
                  <Route path="statistics" element={<PrivateRoute><Statistics /></PrivateRoute>} />
                  <Route path="upload-course" element={<PrivateRoute><ErrorBoundary><CourseUpload /></ErrorBoundary></PrivateRoute>}/>
                </Route>

                <Route path="/chat" element={<PrivateRoute><ErrorBoundary><Chat /></ErrorBoundary></PrivateRoute>} />
                <Route path="*" element={<NotFound />} />

              </Routes>

            </div>
            <Footer></Footer>
          </BrowserRouter>

        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>


    /* </ThemeContext.Provider> */

    //  </ThemeProvider>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //     <Link to="/coursewatching">coursewatching</Link>
    //   </header>
    //   <video width="550" height="300" controls data-from-first="video/1" >
    //     <source src="/do_I_wanna.mp4" type="video/mp4"/>
    //     {/* <source src={"https://www.youtube.com/watch?v=RxQ-m9GtKuI&ab_channel=HowtoSpanishLessons%26Podcast"} type="video/mp4" /> */}
    //   </video>
    // </div>
  );
}

export default App;
