
import { useEffect, useRef, useState } from 'react';
import { FaPaintBrush } from 'react-icons/fa';
import { VideoJSUseMode } from '../../enums/videojs-use-mode.enum';

import useDraggable from '../../hooks/use-draggable';
import { InteractiveCard } from '../../interfaces/interactive-card.interface';
import './draggable-component.scss';

const DraggableComponent = (props:
  {useMode: VideoJSUseMode,
    data: InteractiveCard,
    // cardId: string,
    // x: number,
    // y: number,
    // xRel: number,
    // yRel: number,
    changedPos: (x: number, y: number, id: number) => void,
    onClick: any ,
     children:any }
  ) => {
  const cardRef = useRef(null);
  let [dx, dy] = useDraggable(cardRef);
  let startX = 0, startY = 0;
  // let [dx, dy] = [0,0];
  // const [{startX, startY}, setStartPos] = useState({startX: 0, startY: 0})

  useEffect( () =>
    {
      // const {x,y} = getPosition(document.getElementById(props.children));
      // props.data.dx = startX + dx;
      // props.data.dy = startY + dy;

      props.changedPos(dx, dy, props.data.id);
      
      // console.log("outside draggable (dx,dy)", dx,dy);
      // console.log("props.data.dx props.data.dy", props.data.dx, props.data.dy);

    },[dx,dy]
  )

  useEffect( () =>
    {
      // startX = props.data.dx;
      // startY = props.data.dy;
      // const val = getPosition(document.getElementById(props.children));
      const element = document.getElementById(getId(props.data.id));
      if(element) {
        element.style.left = `${props.data.dx}px`;
        element.style.top = `${props.data.dy}px`;
      }

      console.log("got card x,y",props.data.dx, props.data.dy, props.data.id);
      

      // setStartPos({startX: val.x, startY:val.y});
      // console.log("initial pos (x,y)", val);

      
    },[]
  )

//   const getPosition = (element: any) => {
//     var xPosition = 0;
//     var yPosition = 0;

//     console.log(props.children, "getPosition", element.offsetLeft, element.scrollLeft, element.clientLeft);
    
//     // while(element) {
//         xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
//         yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
//     //     element = element.offsetParent;
//     // }

//     // element.offsetLeft = 300;
//     element.style.left = "0px";

//     return { x: xPosition, y: yPosition };
// }

const getId = (id:number):string => {
  return `draggable-${id}`;
} 

  return (
    <div id={getId(props.data.id)} className="card-container"  ref={cardRef}>
      <div className="card">
      {props.useMode === VideoJSUseMode.EDIT && <FaPaintBrush onClick={props.onClick}/>}
      {props.children}
      </div>
     
    </div>
  );
};

export default DraggableComponent;