
// export class CourseVideoData {
//     id: string = '';
//     name: string = '';
//     duration: number = 0;
//     position: number = 1;
//     link: string = '';
//     description: string = '';
// }

// export class CourseReviewData {
//     name: string = '';
//     picture: string = '';
//     description: string = '';
//     rating: number = 1;
//     date: Date = new Date();
// }

// export class CourseQuestionData {
//     name: string = ''; // student user's Question
//     picture: string = '';
//     description: string = '';
//     date: Date = new Date();
//     responses: CourseQuestionResponseData[] = [];
// }

// export class CourseQuestionResponseData {
//     name: string = ''; // teacher or other student
//     picture: string = '';
//     description: string = '';
//     date: Date = new Date();
//     isTeacher: boolean = true;
// }

export class CreateCourseData {
    id: string = '';
    title: string = '';
    description: string = '';
    category: string = '';
    price: number = 0;
    oldprice: number | null = null;
    picture: string = '';
    type: string = '';

    constructor() {
    }
}