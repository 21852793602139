
export class Answer {
    id: number = 0;
    text: string = '';
    isChecked: boolean = false;
}

export class QuestionForm {
    id: number = 0;
    question: string = 'Who am I?';
    answers: Answer[] = [{ text: 'A cow', isChecked: false } as Answer, { text: 'A cat', isChecked: false } as Answer];
    constructor(data: any) {
        Object.assign(this, data);
    }
}

export class QuizzForm {
    questions: QuestionForm[] = [new QuestionForm({})];

    toResponse = () => {
        const answers = this.questions.map(question => {
            const answers = question.answers.map(answer => {return {answerId: answer.id, selected: answer.isChecked}});
            return {questionId: question.id, answers: answers};
        })

        return {userAnswers: answers};

    }
}

// {
//     "userAnswers": [
//         {
//             "questionId": 1,
//             "answers": [
//                 {
//                     "answerId": 1,
//                     "selected": false
//                 },
//                 {
//                     "answerId": 2,
//                     "selected": true
//                 },
//                 {
//                     "answerId": 3,
//                     "selected": false
//                 }
//             ]
//         }
//     ]
// }
