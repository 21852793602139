import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VideoJSUseMode } from '../../enums/videojs-use-mode.enum';
import { QuestionCard } from '../../models/interactive-video/question-card.model';
import { QuizzForm } from '../../models/interactive-video/quizz-form.data';
import IntActionService from '../../services/InteractiveService';
import './question-session-form.scss';

export class QuestionCardProps {
  questionCard: QuestionCard = new QuestionCard({});
  videoId: number = 0;
  useMode: VideoJSUseMode = VideoJSUseMode.WATCH;
}

function QuestionSessionForm
  (props: QuestionCardProps) {
  // const [checked, setChecked] = React.useState(false);
  const { id } = useParams();
  const [quiz, setQuiz] = React.useState<QuizzForm>(props.questionCard.toQuizzForm());
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  useEffect(() => {
    console.log("quiz",quiz, props.questionCard);
    setQuiz(props.questionCard.toQuizzForm());
  }, [props.questionCard])

  const handleChange = (questionIndex: number, answerIndex: number) => (event: any) => {
    // setChecked(event.target.checked);
    let newQuiz: QuizzForm = new QuizzForm();
    Object.assign(newQuiz, quiz);
    console.log("q i", questionIndex, answerIndex, event.target.checked, newQuiz);
    
    newQuiz.questions[questionIndex].answers[answerIndex].isChecked = event.target.checked;
    setQuiz(newQuiz);
  }; 

  const submitForm = () => {
    IntActionService.submitQuiz( id ?? '', props.videoId, props.questionCard.id, quiz.toResponse());
    setIsSubmitted(true);
  }

  return (
    <div className="question-session-form" style={{backgroundColor: props.questionCard.cardColor}}>

      {!isSubmitted && quiz.questions.map((question, questionIndex) => (
        <div key={`${question.question}_${questionIndex}`} className="question">

          <h3>{question.question}</h3>
          <div className='answer'>
            <FormGroup>
              {
                question.answers.map((answer, answerIndex) => (

                  <FormControlLabel key={`${answer}_${answerIndex}`} control={<Checkbox checked={answer.isChecked}
                    onChange={handleChange(questionIndex, answerIndex)} />} label={answer.text} />
                ))
              }
            </FormGroup>
            

          </div>
          {/* <FormGroup> */}


          {/* <p>a. egy macska</p>
        <p>a. egy macska</p>
        <p>a. egy macska</p> */}
          {/* <div>b. egy nyuszi</div>
      <div>c. egy Pami Macska</div> */}

          {/* <FormControlLabel control={<Checkbox />} label="Disabled" /> */}
        </div>))}
        {/* <Button onClick={submitForm}></Button> */}

        {!isSubmitted && props.useMode === VideoJSUseMode.WATCH && <Button  variant="contained" onClick={submitForm}>Submit</Button>}
        {isSubmitted && <div>Submitted! :)</div>}
    </div>

  );
}

export default QuestionSessionForm;
