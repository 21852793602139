import { useEffect, useState } from 'react';
import Article from '../../components/article/article';
import SmallTv, { TvColor } from '../../components/small-tv/small-tv';
import { usePurchasedCourses } from '../../hooks/use-courses';
import useWindowDimensions from '../../hooks/use-window-dimensions';
import FavouritesService from '../../services/FavouritesService';
import { useQueryClient } from "react-query";
import './my-courses.scss';
import { getRandomBoolean } from '../../utils/functions';

function MyCourses() {
  const { isLoading, data } = usePurchasedCourses();
  const [chunkSize, setChunkSize] = useState(2);
  const [rowData, setRowData] = useState<any[]>([]);

  const { width, height } = useWindowDimensions();
  const queryClient = useQueryClient()
  const [randomArray, setRandomArray] = useState<boolean[]>([]);
  const randomArraySize = 100;

  useEffect(() => {
    const randArr: boolean[] = [];
    for (let i = 0; i < randomArraySize; i++) {
      randArr.push(getRandomBoolean());
    }
    setRandomArray(randArr);
  }, [])

  const toggleFavourites = async (courseId: string) => {
    try {
      if (!courseId) return;
      await FavouritesService.toggleFavourites(courseId);
      queryClient.invalidateQueries('courses')
    } catch (error) {
      console.log("Error changing favourites", error)
    }
  }
  useEffect(() => {
    setChunkSize(width < 768 ? 1 : (width < 1200 ? 2 : 3));
  }, [width])

  useEffect(() => {
    if (data && data.data) {
      const newData: any[] = [];
      for (let i = 0; i < data.data.length; i += chunkSize) {
        const chunk = data.data.slice(i, i + chunkSize);
        newData.push(chunk);
      }
      setRowData(newData);
    }

  }, [chunkSize, data])


  if (isLoading) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div className="courses">
        <div className='courses__content'>
          {
            rowData && rowData.map((row: any[], index: number) => {
              return <div className='shelf-wrapper' key={index}>
                <div className='shelf-wrapper__content'> {
                  row.map((course: any) => {
                    return (
                      <div key={course.id}>
                        {
                          course.type === 'video' &&
                          <SmallTv
                            id={course.id}
                            title={course.title}
                            picture={course.picture}
                            purchased={true}
                            rating={course.rating}
                            studentNr={course.studentNr}
                            favourite={course.favourite}
                            hasSideButtons={randomArray[index % randomArraySize]}
                            hasBottomButton={randomArray[(index + 1) % randomArraySize]}
                            toggleFavourites={toggleFavourites}
                            onClickBasePath='/courses'
                            color={randomArray[(index + 2) % randomArraySize] ? TvColor.BROWN : TvColor.GREY}
                          />}

                        {
                          course.type === 'article' &&
                          <Article title={course.title}
                            picture={course.picture}
                            price={course.price}
                            hasSideButtons={getRandomBoolean()}
                            hasBottomButton={getRandomBoolean()}
                            color={getRandomBoolean() ? TvColor.BROWN : TvColor.GREY} />
                        }
                      </div>
                    )
                  })}
                </div>
                <div className='shelf'>
                  <div className='shelf__wood'>
                  </div>
                  <div className='shelf__wood-front'>
                  </div>
                </div>
              </div>

            })
          }

        </div>
      </div>
    );
  }
}

export default MyCourses;
