
export class Category {
    id: string = '';
    name: string = '';
    parent?: string = '';
}

export class CourseVideoData {
    id: string = '';
    title: string = '';
    slug: string = '';
    duration: number = 0;
    position: number = 1;
    link: string = '';
    streamLink: string = '';
    description: string = '';
    public: boolean = true;
}

export class CourseReviewData {
    name: string = '';
    picture: string = '';
    description: string = '';
    rating: number = 1;
    date: Date = new Date();
}

export class CourseQuestionData {
    name: string = ''; // student user's Question
    picture: string = '';
    description: string = '';
    date: Date = new Date();
    responses: CourseQuestionResponseData[] = [];
}

export class CourseQuestionResponseData {
    name: string = ''; // teacher or other student
    picture: string = '';
    description: string = '';
    date: Date = new Date();
    isTeacher: boolean = true;
}

export class CourseData {
    id: string = '';
    title: string = '';
    description: string = '';
    category: string = '';
    price: number = 0;
    oldprice: number | null = null;
    rating: number = 0;
    picture: string = '';
    favourite: boolean = false;
    purchased: boolean = false;
    type: string = '';
    studentNr: number = 0;
    videos: CourseVideoData[] = [];
    reviews: CourseReviewData[] = [];
    questions: CourseQuestionData[] = [];
    introVideo: number = 0;

    constructor(data: any) {
        Object.assign(this, data);
    }
}