import AuthService from './AuthService';
import HttpService from './HttpService.js';

const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;
const getUsername = () => {
  return AuthService.getParsedToken()!['preferred_username'] || null;
}

const getUserId = () => {
  return AuthService.getParsedToken()?.sub || null;
}

const ensureUserExists = async () => {
  try {
    const response = await axios.post(`${baseApiUrl}/users`, {
      id: getUserId(),
    });
    if (response.status == 201) {
      return response.data.newUser || true;
    }
    return false;
  } catch (error) {
    return false;
  }

};

const getLoggedInUser = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/users/${getUserId()}`);
    if (response.status == 200) {
      return response.data || null;
    }
  } catch (error) {
    console.log('Unknown error occured');
    console.log(error);
  }
  return false;
};

const getAllUsers = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/users`);
    if (response.status == 200) {
      return response.data || null;
    }
  } catch (error) {
    console.log('Unknown error occured', error);
  }
  return null;
};

const getTeachers = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/users/role/teacher`);
    if (response.status == 200) {
      return response.data || null;
    }
  } catch (error) {
    console.log('Unknown error occured', error);
  }
  return null;
};

export const deleteUser = async (userId: number): Promise<void> => {
  await fetch(`${baseApiUrl}/users/${userId}`, {
    method: 'DELETE',
  });
};

const UserService = {
  ensureUserExists,
  getUserId,
  getUsername,
  getLoggedInUser,
  getTeachers,
  getAllUsers
};

export default UserService;
