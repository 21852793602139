export class Question {
  id?: string = '';
  question: string = '';
  answers: Answer[] = [];
  singleAnswer: boolean = false;
  // duration: number;
}

export class Answer {
  id?: string = '';
  text: string = '';
  isCorrect: boolean|null = null;
}

export {}