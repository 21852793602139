import { useUser } from '../../hooks/use-user';
import './user-profile.scss';

function CoursesTeaching() {
  const { isLoading, data } = useUser();

  if (isLoading || !data) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div className="courses">
        <div className='courses__content'>
          <h2>Welcome {data?.data.name}</h2>
          <p>You registered on {data?.data.registrationDate.split('T')[0]}</p>
        </div>
      </div>
    );
  }
}

export default CoursesTeaching;
