import HttpService from './HttpService.js';

const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

const toggleFavourites = async (courseId: string) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/favourites`);
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const FavouritesService = {
  toggleFavourites
};

export default FavouritesService;
