import React, { useEffect } from 'react';
import './teaching.scss';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Gravatar from 'react-gravatar';
import AuthService from '../../services/AuthService';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function Teaching() {
  const [value, setValue] = React.useState('1');
  const [progress, setProgress] = React.useState(10);
  const { pathname } = useLocation();

  let navigate = useNavigate();
  const navigateTo = (path: string) => {
    return () => navigate(path)
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (pathname === "/teaching/my-courses") {
      setValue('1');
    } else if (pathname === "/teaching/upload-course") {
      setValue('2');
    } else if (pathname === "/teaching/create-category") {
      setValue('3');
    }
  }, [pathname])

  return (
    <div className="teaching">
      <div className="teaching__header">
        <div className="teaching__header__picture">
          <Gravatar email={AuthService.getParsedToken()?.email}
            size={300}
            // @ts-ignore
            default="robohash" />
        </div>
      </div>

      <div className="teaching__cont-wrapper">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="teaching" centered>
                <Tab label="My courses" value="1" onClick={navigateTo('my-courses')} />
                <Tab label="Course Upload" value="2" onClick={navigateTo('upload-course')} />
                <Tab label="Create Category" value="3" onClick={navigateTo('create-category')} />
                <Tab label="Marketing" value="4" onClick={navigateTo('marketing')} />
                <Tab label="Statistics" value="5" onClick={navigateTo('statistics')} />
              </TabList>
            </Box>
            <TabPanel value="1">
            </TabPanel>
            <TabPanel value="2">
            </TabPanel>
            <TabPanel value="3">
            </TabPanel>
            <TabPanel value="4">
            </TabPanel>
            <TabPanel value="5">
            </TabPanel>
          </TabContext>
        </Box>
        <Outlet />
      </div>
    </div>
  );
}

export default Teaching;
