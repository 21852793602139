import { useUser } from '../../hooks/use-user';
import './progress.scss';
import Box from '@mui/material/Box';
import { LinearProgress, Typography } from '@mui/material';
import { useProgress } from '../../hooks/use-progress';

function LinearProgressWithLabel(props: any) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
function Progress() {
  const { isLoading, data } = useProgress();

  if (isLoading || !data) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div>
        <h2>Learning progress</h2>
        {data.data && data.data.map((progress: any) =>
          <Box key={progress.course} sx={{ width: '70%', padding: '12px 0' }}>
            {progress.courseTitle}
            <LinearProgressWithLabel value={progress.percentage} />
          </Box>
        )}
      </div>
    );
  }
}

export default Progress;
