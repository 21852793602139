import './landing-page.scss';
import Header from '../../components/header/header';
import BestCourses from '../../components/best-courses/best-courses';

function LandingPage() {
  return (
    <div className="landing-page">
      <Header />
      <BestCourses />
      <div className='teacher-section'>
        <h2>Learning was never before as existing as now</h2>
        <h2>Your Teacher</h2>
        <div className="teacher-card">
          <div className="teacher-card__profile">
            <picture>
              <img src='/assets/krisztian.jfif' alt='teacher'></img>
            </picture>
            <h3>Sala Krisztián</h3>
          </div>
          <div className="teacher-card__description">
            <p>
              <p>I work as a DevOps Engineer with a passion for web development, teaching and automating things. I'm finishing up my Master's degree in business application design and development.</p>

              <p>I enjoy using multiple concepts and technologies in my projects like microservices, Kubernetes, cloud computing, infrastructure as code, CI/CD pipelines, observability and security tools.</p>

              <p>On the development side, I love doing both backend and frontend work and use mainly Typescript, Java or Go with different frameworks like React, Express, Spring and tools, like message queues, SQL and NoSQL databases, caches, etc.</p>

              <p>Teaching and helping others has become my passion, that is why I started to create online courses, to share my knowledge with more people (because I believe the Internet can be the greatest source of information in many areas, but especially in programming). I also write blog posts about my recent experiences from time to time.</p>

              <p>I also love spending time with my family, learning new things and doing sports.</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
