import { CreateCourseData } from '../models/course-data/createCourseData.model';
import { UpdateCourseData } from '../models/course-data/updateCourseData.model';
import HttpService from './HttpService.js';

const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

const createCourse = async (course: CreateCourseData) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses`, course);
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const updateCourse = async (course: UpdateCourseData) => {
  try {
    const response = await axios.patch(`${baseApiUrl}/courses/${course.id}`, course);
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const deleteCourse = async (courseId: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`${baseApiUrl}/courses/${courseId}`);
    if (response.status == 204) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const CourseService = {
  createCourse,
  updateCourse,
  deleteCourse
};

export default CourseService;
