import HttpService from './HttpService.js';

const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

const createIntAction = async (courseId: string, videoId: number, intAction: any) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/content/${videoId}/actions`, intAction);
    console.log(response)
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const updateIntAction = async (courseId: string, videoId: number, intAction: any) => {
  try {
    const response = await axios.put(`${baseApiUrl}/courses/${courseId}/content/${videoId}/actions`, intAction);
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const deleteIntAction = async (courseId: string, videoId: number, intActionId: any) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/courses/${courseId}/content/${videoId}/actions/${intActionId}`,);
    if (response.status == 204) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const getIntActions = async (courseId: string, videoId: number) => {
  try {
    const response = await axios.get(`${baseApiUrl}/courses/${courseId}/content/${videoId}/actions`);
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
// api/courses/chrome-extensions/content/7/actions/13/quiz
const submitQuiz = async (courseId: string, videoId: number, actionId: number, quiz: any) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/content/${videoId}/actions/${actionId}/quiz`, quiz);
    console.log(response)
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const IntActionService = {
  getIntActions,
  createIntAction,
  updateIntAction,
  deleteIntAction,
  submitQuiz
};

export default IntActionService;
