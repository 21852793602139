import { useEffect, useState } from 'react';
import { useMyBadges } from '../../hooks/use-badges';
import './my-badges.scss';
import { badges as badgeList } from '../../constants/constants';
import { BadgeData } from '../../models/statistics/badgeData.model';
import BagdeCard from '../../components/bagde-card/bagde-card';

function MyBadges() {
  const { isLoading, data } = useMyBadges();
  const [badges, setBadges] = useState<BadgeData[]>([]);


  useEffect(() => {
   setBadges(badgeList);
  }, [])

  useEffect(() => {
    if(!data || !data.data) return;
    const myBadges = badgeList.map(badge => {
      if(data.data.includes(badge.id)) {
        badge.achieved = true;
      }
      return badge;
    })
   setBadges(myBadges);
  }, [data])


  if (isLoading) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div className="my-badges">
      <h1 className="my-badges__title">
        --- My badges ---
        </h1>
      <div className="my-badges__content">
        {badges.map(badge => (
          <BagdeCard badge={badge} key={badge.id}></BagdeCard>
        ))}
      </div>
      </div>
    );
  }
}

export default MyBadges;
