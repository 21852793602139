import { CourseVideoData } from '../models/course-data/courseData.model';
import HttpService from './HttpService.js';

const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

const createVideo = async (courseId: string, video: CourseVideoData) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/content`, video);
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const updateVideo = async (courseId: string, video: CourseVideoData) => {
  try {
    const response = await axios.put(`${baseApiUrl}/courses/${courseId}/content/${video.id}`, video);
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const deleteVideo = async (courseId: string, videoId: number) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/courses/${courseId}/content/${videoId}`);
    return response.status == 204;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const reorderVideos = async (courseId: string, videos: CourseVideoData[]) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/content/reorder`, videos);
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const setIntroVideo = async (courseId: string, videoId: number) => {
  try {
    const response = await axios.patch(`${baseApiUrl}/courses/${courseId}/intro/${videoId}`);
    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const VideoService = {
  createVideo,
  updateVideo,
  reorderVideos,
  deleteVideo,
  setIntroVideo
};

export default VideoService;
