import './button.scss';

export class TextChunks {
  text: string = '';
  bold?: boolean = false;
  uppercase?: boolean = false;
}

export class ButtonTextConfig {
  textConfig: TextChunks[] = []
  onClick = () => {}
}
// textConfig = [{text: string, bold: boolean, uppercase: boolean}]
function Button
  (props: ButtonTextConfig) {
    // let navigate = useNavigate();
    // const navigateTo = () => navigate('/courses');//eg.history.push('/login');

  return (
    <div className="button" onClick={props.onClick}>
      <h3 className='button__text'>
      {props.textConfig.map(val =>
        <span key={val.text} className={`button__text-chunk ${val.bold ? 'button__text-chunk--bold' : 'button__text-chunk--regular' }
         ${val.uppercase !== undefined ? (val.uppercase ? 'button__text-chunk--uppercase' : 'button__text-chunk--lowercase') : '' }`}>
          {val.text}
        </span>)}
      </h3>     
    </div>
  );
}

export default Button;
