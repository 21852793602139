import { IconContext } from "react-icons";
import { FaSun } from "react-icons/fa";

export const YellowSunProvider = () => {
    return (
      <IconContext.Provider
        value={{ color: '#ffd82b', size: '3rem' }}
      >
        <div>
          <FaSun />
        </div>
      </IconContext.Provider>
    );
  }