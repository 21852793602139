import { useQuery } from "react-query";
import { CourseData } from "../models/course-data/courseData.model";
import HttpService from '../services/HttpService.js';
import AuthService from '../services/AuthService';
const axios = HttpService.getAxiosClient();

export const getCourse = (id: string) => {
    return axios.get(`${process.env.REACT_APP_HOST}/api/courses/${id}`);
}
export const useCourse = (id:string) => {
    const response = useQuery(['courses', id, AuthService.isLoggedIn()], () => getCourse(id));

    return {
        ...response,
        data: new CourseData(response.data?.data)
    }
}