export const markdownExample = `Try to put a blank line before...

# Heading

...and after a heading.

I really like using Markdown.

I think I'll use it to format all of my documents from now on.
I really like using Markdown.

I think I'll use it to format all of my documents from now on.

I just love **bold text**.

I just love __bold text__.

Italicized text is the *cat's meow*.

> Dorothy followed her through many of the beautiful rooms in her castle.`;

export const uploadBlockSize = 131072;
export const padding = {
    'padding-xxl': '19rem',
    'padding-xl': '10rem',
    'padding-l': '5.6rem',
    'padding-m': '3.2rem',
    'padding-s': '2.4rem',
    'padding-xs': '1.2rem',
    'padding-xxs': '.6rem',
};
export const colors = {
    'grey-1': '#1B1512',
    'grey-2': '#312E2C',
    'grey-3': '#6B6468',
    'grey-4': '#A6A0A3',
    'grey-5': '#D2D2D2',
    'grey-6': '#F5F5F5',
    'white': 'white',
    'brown-1': '#513F2B',
    'brown-2': '#8B6F4D',
    'orange': '#FA7528',
    'pink': '#EB3474',
    'green': '#14F7DD',
    'orange-50': '#fa752888',
    'pink-50': '#EB347488',
    'green-50': '#14F7DD88',
    'grey-50': '#D2D2D288',
    'black-50': '#00000088',
}