import { useState } from "react";
import { ThemeContext } from "../../context/theme-context";
import { ThemeType } from "../../enums/theme-type.enum";

export const ThemeProvider = ({children}: any) => {
    const [theme, setTheme] = useState(ThemeType.DARK);

    const changeTheme = () => {
        setTheme(currentTheme => currentTheme === ThemeType.DARK ? ThemeType.LIGHT : ThemeType.DARK);
    };

    return (
        <ThemeContext.Provider value={{theme, changeTheme}}>
        {children}
        </ThemeContext.Provider>
    
    )
}

//  export const ThemeProvider = ({children}: any) => {
//     return (
//         <>
        
//         <ThemeContext.Provider>
//         </ThemeContext.Provider>
//         </>
    
//     )
// }