import { useQuery } from "react-query";
import AuthService from "../services/AuthService";
import HttpService from '../services/HttpService.js';
const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

export const useUser = () => {
    return useQuery('user', () => {
        const userId = AuthService.getParsedToken()?.sub;
        if(!userId) return null;
        const resp = axios.get(`${baseApiUrl}/users/${userId}`);
        return resp;
    });
}