import { useQuery } from "react-query";
import StatisticsService from '../services/StatisticsService';

export const useProgress = () => {
  return useQuery(['statistics', 'progress'], () => {
    return StatisticsService.getProgress();
  });
}
export const useViews = (days = 7) => {
  return useQuery(['statistics', 'views', days], () => {
    return StatisticsService.getVideoViews(days);
  });
}
export const useViewsTeacher = (days = 7) => {
  return useQuery(['statistics', 'teacher', 'views', days], () => {
    return StatisticsService.getVideoViewsTeacher(days);
  });
}
export const usePurchases = (days = 7) => {
  return useQuery(['statistics', 'teacher', 'purchases', days], () => {
    return StatisticsService.getPurchases(days);
  });
}