import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import './course-navigate-component.scss';
import { useNavigate } from 'react-router-dom';
import { CourseCard } from '../../models/interactive-video/course-card.model';
import { VideoJSUseMode } from '../../enums/videojs-use-mode.enum';


export class CourseNavigateProps {
  courseCard: CourseCard = new CourseCard({});
  videoJsUseMode?: VideoJSUseMode = VideoJSUseMode.WATCH;
}

function CourseNavigateComponent
  (props: CourseNavigateProps) {
  let navigate = useNavigate();
  const navigateTo = (path: string) => {
    // console.log("clicked", path);
    if (props.videoJsUseMode === VideoJSUseMode.WATCH) {
      return () => navigate(path)
    }
    return () => { }
  };
  // const title = 'Title of the course';
  // const courseId = '99';
  // const courseLen = '6 hours';
  // const imageUrl = 'https://i.pinimg.com/originals/f0/52/ee/f052ee1a651e8b57aa88e06e38bbcd22.gif'

  return (
    <div className="course-navigate-component" style={{ backgroundColor: props.courseCard.cardColor }} onClick={navigateTo(`/courses/${props.courseCard.courseId}`)}>
      {/* <img src='https://miro.medium.com/max/1400/1*1HiMZ1JFtQ2vaXL9z8dkDg.jpeg'></img> */}
      <img src={props.courseCard.imageUrl} alt="img" />
      <div className='course-navigate-component__title'>{props.courseCard.title}</div>
      <div className='course-navigate-component__duration'>{props.courseCard.courseLenFormatted}</div>
    </div>

  );
}

export default CourseNavigateComponent;
