import { useQuery, useMutation, useQueryClient } from "react-query";
import { CreateReviewData } from "../models/review-data/reviewData.model";
import HttpService from '../services/HttpService.js';
const axios = HttpService.getAxiosClient();
const baseApiUrl = `${process.env.REACT_APP_HOST}/api`;

export const useReviews = (courseId: string) => {
    return useQuery('reviews', () => {
        const resp = axios.get(`${baseApiUrl}/courses/${courseId}/reviews`);
        return resp;
    });
}

export const createReview = async (courseId: string, review: CreateReviewData) => {
  try {
    const response = await axios.post(`${baseApiUrl}/courses/${courseId}/reviews`, review);
    if (response.status == 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};