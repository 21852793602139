import SmallTv, { TvColor } from '../small-tv/small-tv';
import './best-courses.scss';
import { useBestCourses } from '../../hooks/use-courses';

function BestCourses() {
  const { isLoading, data } = useBestCourses();

  if (isLoading) {
    return (<div>
      Loading...
    </div>)
  } else {
    return (
      <div className="best-courses">
        <h2>Best Video Courses</h2>
        <div className="best-courses__tvs">
          {data && data.data && data.data.length == 2 &&
            <>
              <SmallTv id={data.data[0].id}
                title={data.data[0].title}
                picture={data.data[0].picture}
                rating={data.data[0].rating}
                hasSideButtons={false}
                hasBottomButton={true}
                color={TvColor.GREY}
              />
              <SmallTv id={data.data[1].id}
                title={data.data[1].title}
                picture={data.data[1].picture}
                rating={data.data[1].rating}
                hasSideButtons={true}
                hasBottomButton={false}
                color={TvColor.BROWN}
              />
            </>}

        </div>
      </div >
    );
  }
}

export default BestCourses;
