import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { useMutation } from "react-query";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Alert, Box, Checkbox, FormControlLabel, Input, InputAdornment, InputLabel, LinearProgress, Snackbar } from "@mui/material";
import Modal from '@mui/material/Modal';
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import remarkGfm from "remark-gfm";
import { AddEditFormType } from "../../../enums/add-edit-form-type.enum";
import { CourseType } from "../../../enums/course-type.enum";
import { ThemeType } from "../../../enums/theme-type.enum";
import { useTheme } from "../../../hooks/use-theme";
import { CreateCourseData } from "../../../models/course-data/createCourseData.model";
import { UpdateCourseData } from "../../../models/course-data/updateCourseData.model";
import { Category, CourseVideoData } from "../../../models/course-data/courseData.model";
import useWindowDimensions from "../../../hooks/use-window-dimensions";
import InteractiveVideoEditor from "../../interactive-video-editor/interactive-video-editor";
import HttpService from "../../../services/HttpService";
import CourseService from "../../../services/CourseService";
import { createSlug } from "../../../utils/functions";
import CategorySelect from "../../../components/category-select/category-select";
import './add-edit-course.scss'
import { markdownExample, uploadBlockSize } from "../../../utils/constants";
import { getCourse } from "../../../hooks/use-course";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import VideoService from "../../../services/VideoService";
import io from "socket.io-client";
import { InteractiveCard } from "../../../interfaces/interactive-card.interface";
import IntActionService from "../../../services/InteractiveService";
import { pink } from "@mui/material/colors";
// import { useInteractiveVideo } from "../../../hooks/use-interactive-video";

export class AddEditCourseProps {
  type: AddEditFormType = AddEditFormType.EDIT;
  courseType: CourseType = CourseType.VIDEO;
  courseToUpdate?: UpdateCourseData;
  courseVideoData?: CourseVideoData[];
  categories?: Category[];
}

export class VideoObject {
  file?: File;
  fileSize: number = 0;
  percent: number = 0;
}
const axios = HttpService.getAxiosClient();

let socket: any = null;
let fileReader: FileReader = new FileReader();
let socketUrl = process.env.REACT_APP_WEBSOCKET_HOST ?? 'http://localhost:5000';

function AddEditCourse(props: AddEditCourseProps) {
  const { id } = useParams();
  const { theme } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [course, setCourse] = useState<CreateCourseData | UpdateCourseData>(props.courseToUpdate ?? new CreateCourseData());
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [slugEditedManually, setSlugEditedManually] = useState<boolean>(false);
  const { width, height } = useWindowDimensions();
  const [thumbnailPhoto, setThumbnailPhoto] = useState<File>();
  const [thumbnailPhotoPath, setThumbnailPhotoPath] = useState<string>('');
  const [introVideo, setIntroVideo] = useState<number>(-1);
  const [interactiveCards, setInteractiveCards] = useState<InteractiveCard[]>([]);
  const [newInteractiveCards, setNewInteractiveCards] = useState<InteractiveCard[]>([]);
  // let interactiveVideoData; // {isLoading, data}
  // let interactiveVideoData = useInteractiveVideo(id, parseInt(course?.videos[actualVideoIndex]?.id)); // {isLoading, data}

  useEffect(() => {
    const fetchData = async () => {
      const courseData = await getCourse(id ?? '0');
      if (props.type === AddEditFormType.EDIT) {
        if (courseData.data.id) {
          const crs = courseData.data;
          const existingCourse = {
            id: crs.id,
            title: crs.title,
            description: crs.description,
            category: crs.category,
            price: crs.price,
            oldprice: crs.oldprice,
            picture: crs.picture,
            type: crs.type,
          };
          setCourse(existingCourse);
          setVideos(crs.videos)
          setSelectedCategory(crs.category);
          setIntroVideo(crs.introVideo);
          // setInteractiveCards()
        }
      }
    };
    fetchData();
    socket = io(socketUrl);
    socket.on('uploadDone', uploadDone);
    socket.on('moreData', moreData);
    return () => {
      socket.off('uploadDone', uploadDone);
      socket.off('moreData', moreData);
    }
  }, [])
  let navigate = useNavigate();

  // --- COURSE section ---

  const submitBtnText = () => {
    let text = props.type === AddEditFormType.ADD ? 'Create' : 'Update';
    const type = props.courseType === CourseType.VIDEO ? 'course' : 'article';
    return `${text} ${type}`;
  }

  const createCourse = useMutation((course: CreateCourseData) => {
    return CourseService.createCourse(course);
  })

  const updateCourse = useMutation((course: UpdateCourseData) => {
    return CourseService.updateCourse(course);
  })

  const handleCourseTitleChange = (event: any) => {
    let title = event.target.value;
    if ((!slugEditedManually || !course.id) && props.type == AddEditFormType.ADD) {
      const slug = createSlug(title);
      setCourse({ ...course, [event.target.name]: title, id: slug });
    } else {
      setCourse({ ...course, [event.target.name]: title });
    }
  };
  const handleCourseSlugChange = (event: any) => {
    setCourse({ ...course, id: event.target.value });
    if (event.target.value == '') {
      setSlugEditedManually(false);
    } else {
      setSlugEditedManually(true);
    }
  };

  const handleCourseChange = (event: any) => {
    setCourse({ ...course, [event.target.name]: event.target.value });
  }

  const handleImageChange = (event: any) => {
    setThumbnailPhoto(event.target.files[0]);
    setThumbnailPhotoPath(event.target.value);
  }

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  }

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (successMessage) setSuccessMessage('');
    if (errorMessage) setErrorMessage('');
  };

  const fillMarkdownExample = () => {
    setCourse({ ...course, description: markdownExample });
  }

  const uploadPicture = async () => {
    if (!thumbnailPhoto) {
      return;
    }
    const formData = new FormData();
    formData.append("file", thumbnailPhoto);
    const fileSplit = thumbnailPhoto.name.split('.');
    const fileEnding = fileSplit[fileSplit.length - 1];
    const fileExtension = ['jpg', 'jpeg', 'png', 'svg'].includes(fileEnding) ? fileEnding : '';
    let randomString = (Math.random() + 1).toString(36).substring(7);
    let fileName = course.id ?? '';
    fileName += '_' + randomString + (fileExtension ? ('.' + fileExtension) : '');
    formData.append("fileName", fileName);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HOST}/upload`,
        formData
      );
      return res.data.imageUrl;
    } catch (err) {
      console.log("Error uploading picture ", err);
    }
  }

  const submitCourse = async () => {
    setSubmitted(true);
    if (!course.id || !course.title || !course.price || !selectedCategory) {
      console.log("Complete the necessary fields");
      return;
    }
    if (!thumbnailPhoto && !course.picture) {
      console.log("Complete the necessary fields");
      return;
    }
    let courseToUpload = Object.assign({}, course);
    courseToUpload.category = selectedCategory;
    courseToUpload.type = CourseType.VIDEO;
    courseToUpload.oldprice = course.oldprice ? +course.oldprice : 49.99;
    // Using the + to convert it to a number, because for some reason it's a string
    courseToUpload.price = +course.price;
    if (thumbnailPhoto) {
      const uploadedImageUrl = await uploadPicture();
      courseToUpload.picture = uploadedImageUrl;
    }
    let hook = createCourse;
    if (props.type === AddEditFormType.EDIT) {
      hook = updateCourse;
    }
    hook.mutate(courseToUpload, {
      onSuccess: (data) => {
        if (!data) {
          setErrorMessage(`Course ${props.type === AddEditFormType.ADD ? 'creation' : 'update'} failed`);
          return;
        }
        setSuccessMessage(`Course ${props.type === AddEditFormType.ADD ? 'created' : 'updated'} successfully!`)
        navigate(`/teaching/edit-course/${data.id}`)
      },
      onError: (error) => {
        console.log(error);
        setErrorMessage(`Course ${props.type === AddEditFormType.ADD ? 'creation' : 'update'} failed`);
      }
    });
  }


  // --- VIDEO section ---

  // CSS
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: `${height}`,
    bgcolor: 'background.paper',
    color: '#ab6969',
    border: '2px solid #aaaa',
    boxShadow: 24,
    p: 4,
  };

  const modalContentStyle = {
    height: `calc(${height}px - 250px)`,
    position: 'relative',
    overflow: 'auto',
  };
  const grid = 8;

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties => ({
    userSelect: "none",
    fontSize: '16px',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#EB3474" : "#312E2C",
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? "#D2D2D2" : "#A6A0A3",
    padding: grid,
    width: '97%',
    margin: `0 0 2em 0`,
  });

  // Hooks
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenId = (id: string) => {
    const selected = videos.find(v => v.id === id);
    if (selected) {
      setVideo(selected);
      // const interactive = useInteractiveVideo(course.id, parseInt(id));
      // setInteractiveCards(interactive.data);
    }
    setOpen(true);
  }

  const handleClose = () => setOpen(false);
  const [videoFiles, setVideoFiles] = useState<{ [key: string]: VideoObject }>();
  const [videoObject, setVideoObject] = useState<VideoObject>(new VideoObject());
  const [video, setVideo] = useState<CourseVideoData>(new CourseVideoData());
  const [videos, setVideos] = useState<CourseVideoData[]>(props.courseVideoData ?? []);
  const [nextData, setNextData] = useState<{ [key: string]: any }>();
  const [videoSlugEditedManually, setVideoSlugEditedManually] = useState<boolean>(false);
  const [videoToUpload, setVideoToUpload] = useState<string>('');
  const videoInputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const createVideo = useMutation((video: CourseVideoData) => {
    if (!id) return Promise.reject();
    return VideoService.createVideo(id, video);
  })

  const reorderVideos = useMutation((videos: CourseVideoData[]) => {
    if (!id) return Promise.reject();
    return VideoService.reorderVideos(id, videos);
  })

  const updateVideo = useMutation((video: CourseVideoData) => {
    if (!id) return Promise.reject();
    return VideoService.updateVideo(id, video);
  })

  const deleteVideo = useMutation((videoId: number) => {
    if (!id) return Promise.reject();
    return VideoService.deleteVideo(id, videoId);
  })

  const introVideoMutation = useMutation((videoId: number) => {
    if (!id) return Promise.reject();
    return VideoService.setIntroVideo(id, videoId);
  })

  // const createIntActions = useMutation((actions: any, videoId: number) => {
  //   if (!id || !video.id) {
  //     console.log(id, video)
  //     return Promise.reject();
  //   }
  //   return IntActionService.createIntAction(id, videoId, actions);
  // })

  const updateIntActions = useMutation((actions: any) => {
    if (!id || !video.id) return Promise.reject();
    return IntActionService.updateIntAction(id, parseInt(video.id), actions);
  })
  const deleteIntActions = useMutation((actionId: number) => {
    if (!id || !video.id) return Promise.reject();
    return IntActionService.deleteIntAction(id, parseInt(video.id), actionId);
  })

  // Handlers
  const handleVideoChange = (event: any) => {
    setVideo({ ...video, [event.target.name]: event.target.value });
  };

  const handleVideoTitleChange = (event: any) => {
    const title = event.target.value;
    if (!videoSlugEditedManually && !video.id) {
      setVideo({ ...video, title, slug: createSlug(title) });
    } else {
      setVideo({ ...video, title });
    }
  };

  const handleVideoSlugChange = (event: any) => {
    setVideo({ ...video, slug: event.target.value });
    if (event.target.value == '') {
      setVideoSlugEditedManually(false);
    } else {
      setVideoSlugEditedManually(true);
    }
  };

  const handleVideoPublicChange = (event: any) => {
    setVideo({ ...video, public: event.target.checked });
  };

  const handleVideoFileChange = (event: any) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    const newVideo = Object.assign({}, video);
    const newVideoObject = Object.assign({}, videoObject);
    newVideoObject.file = file;
    newVideoObject.fileSize = event.target.files[0].size;
    newVideo.link = url;

    setVideo(newVideo);
    setVideoObject(newVideoObject);
  };

  const reorder = (newVideos: CourseVideoData[]) => {
    for (let i = 0; i < newVideos.length; i++) {
      newVideos[i].position = i;
    }
    reorderVideos.mutate(newVideos, {
      onError: (error) => {
        console.log(error);
        setErrorMessage('Error creating video');
      }
    });
    return newVideos;
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    let newItems = [...videos];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    newItems = reorder(newItems);
    setVideos(newItems)
  }

  const handleSetIntroVideo = (id: string) => {
    introVideoMutation.mutate(parseInt(id), {
      onSuccess: (data) => {
        if (data) {
          setSuccessMessage(`Intro video set successfully!`)
          setIntroVideo(parseInt(id));
        }
        else {
          setErrorMessage('Error setting intro video');
        }
      },
      onError: (error) => {
        console.log(error)
        setErrorMessage('Error setting intro video');
      }
    });
  }
  const handleDeleteVideo = (event: any, id: string) => {
    if (window.confirm('Are you sure you wish to delete this video?')) {
      let newVideos = videos.filter((v) => v.id !== id);
      setVideo(new CourseVideoData());
      setOpen(false);
      deleteVideo.mutate(parseInt(id), {
        onSuccess: (data) => {
          if (data) {
            setSuccessMessage(`Video deleted successfully!`)
          }
          else {
            setErrorMessage('Error deleting video');
          }
        },
        onError: (error) => {
          console.log(error)
          setErrorMessage('Error deleting video');
        }
      });
      newVideos = reorder(newVideos);
      setVideos(newVideos);
    }
  }

  const uploadVideo = (videoId: string, videoObj: VideoObject) => {
    if (videoObj) {
      fileReader.onload = (event: any) => {
        try {
          socket.emit('upload', { 'name': videoObj.file?.name, 'data': event.target.result, 'id': videoId });
        } catch (error) {
          console.log(error)
        }
      }
      socket.emit('startUpload', { 'name': videoObj.file?.name, 'size': videoObj.fileSize, 'id': videoId });
    }
  }

  // This is needed, because moreData doesn't access the updated version of videoFiles
  // Because of socket io is weird
  const moreData = (data: any) => {
    setNextData(data);
  }

  useEffect(() => {
    if (!nextData) return;
    const videoId = nextData['id'].toString();
    if (videoFiles && videoFiles.hasOwnProperty(videoId)) {
      const videoObject = Object.assign({}, (videoFiles[videoId]));
      videoObject.percent = nextData['percent'];
      const place = nextData['place'] * uploadBlockSize; //The Next Blocks Starting Position
      const newFileSlice = videoObject.file?.slice(place, place + Math.min(uploadBlockSize, (videoObject.fileSize - place)));
      if (newFileSlice) fileReader.readAsBinaryString(newFileSlice);

      setVideoFiles({ ...videoFiles, [videoId]: videoObject });
    };
  }, [nextData])

  const uploadDone = (data: any) => {
    const newVideoFiles = Object.assign({}, videoFiles);
    delete newVideoFiles[data.id];
    setVideoFiles(newVideoFiles);
    setSuccessMessage(`${data.fileName} uploaded successfully`);
  }

  const submitInteractivities = async (videoId: number) => {
    if (!id || !videoId) return;
    try {
      let resp, resp2;
      if(props.type === AddEditFormType.ADD) {
        resp = await IntActionService.createIntAction(id, videoId, interactiveCards);
      } else {
        resp = await IntActionService.updateIntAction(id, videoId, interactiveCards.filter(card => !newInteractiveCards.map(card => card.id).includes(card.id)));
        resp = await IntActionService.createIntAction(id, videoId, interactiveCards.filter(card => newInteractiveCards.map(card => card.id).includes(card.id)));
      }

      if (resp == null) {
        // setErrorMessage('Error creating interactivities');
      } else {
        setSuccessMessage(`Interactivities created successfully!`)
      }
    } catch (error) {
      console.log(error)
      // setErrorMessage('Error creating interactivities');
    } finally {
      setInteractiveCards([]);
      setNewInteractiveCards([]);
    }
  }

  const addNewVideo = async () => {
    if (!video.title || (!video.id && !video.link)) return;
    // Editing 
    if (video.id) {
      const newVideos = [...videos];
      const indexToUpdate = newVideos.findIndex((vid => vid.id === video.id));
      newVideos[indexToUpdate] = video;
      updateVideo.mutate(video, {
        onSuccess: (data) => {
          if (data) {
            setSuccessMessage(`Video updated successfully!`)
            if (interactiveCards.length > 0) {
              submitInteractivities(data.id);
            }
            setVideos(newVideos);
            if (introVideo === -1) {
              console.log("This should have never been called.")
              handleSetIntroVideo(video.id);
            }
            // Upload new video only if a file has been selected
            if (videoObject.file) {
              setVideoFiles({ ...videoFiles, [data.id.toString()]: videoObject });
              setVideoToUpload(data.id.toString());
            }
          }
          else {
            setErrorMessage('Error updating video');
          }
        },
        onError: (error) => {
          console.log(error)
          setErrorMessage('Error updating video');
        }
      });
    }
    // Uploading
    else {
      const newVideo = Object.assign({}, video);
      newVideo.position = videos.length;
      createVideo.mutate(newVideo, {
        onSuccess: async (data) => {
          if (data) {
            setSuccessMessage(`Video created successfully!`);
            if (interactiveCards.length > 0) {
              submitInteractivities(data.id);
            }
            newVideo.id = data.id;
            if (introVideo === -1) {
              handleSetIntroVideo(newVideo.id);
            }
            setVideos(oldVideos => [...oldVideos, newVideo]);
            const newVideoObj = Object.assign({}, videoObject);
            setVideoFiles({ ...videoFiles, [data.id.toString()]: newVideoObj });
            setVideoToUpload(data.id.toString());
          } else {
            setErrorMessage('Error creating video');
          }
        }
      });
    }
    setVideo(new CourseVideoData());
    setVideoSlugEditedManually(false);
    setOpen(false);
  }

  // This is needed, so the upload starts only when the object is added to the videoFiles map
  useEffect(() => {
    if (videoToUpload == '' || !videoFiles?.hasOwnProperty(videoToUpload)) return;
    uploadVideo(videoToUpload, videoObject);
    setVideoObject(new VideoObject());
    setVideoToUpload('');
  }, [videoFiles, videoToUpload])

  return (
    <div className={`add-edit-course ${theme === ThemeType.DARK ? 'add-edit-course--dark' : 'add-edit-course--light'}`}>
      <div className="add-edit-course__input">
        <TextField
          className="input-elem"
          id="outlined-textarea"
          label="Title"
          color="secondary"
          placeholder="Title..."
          name="title"
          value={course.title}
          onChange={handleCourseTitleChange}
          error={submitted && !course.title}
        />
      </div>
      <div className="add-edit-course__input">
        <TextField
          className="input-elem"
          id="slug"
          label="Slug"
          color="secondary"
          placeholder="Slug..."
          name="slug"
          value={course.id}
          onChange={handleCourseSlugChange}
          error={submitted && !course.id}
          disabled={props.type === AddEditFormType.EDIT}
        />
      </div>

      <div className="add-edit-course__input">
        <div className="row relative">
          <InputLabel htmlFor="import-button" className="input-label" >
            Thumbnail image
          </InputLabel>
          <Input
            inputProps={{
              accept:
                "image/*",
            }}
            className="input-elem photo-input"
            id="icon-button-photo"
            name="picture"
            value={thumbnailPhotoPath}
            onChange={handleImageChange}
            type="file"
            error={submitted && !thumbnailPhotoPath && props.type == AddEditFormType.ADD}
          />
        </div>
      </div>
      <CategorySelect selectedCategory={selectedCategory} handleCategoryChange={handleCategoryChange} submitted={submitted} />

      {props.courseType === CourseType.VIDEO && <div className="add-edit-course__input">
        <h3>Price</h3>

        <div className="row">
          <TextField
            className="input-elem"
            id="outlined-textarea"
            label="Actual Price"
            color="secondary"
            placeholder="Actual price..."
            name="price"
            value={course.price}
            type="number"
            InputProps={{
              inputProps: { min: 0, max: 1000 },
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={handleCourseChange}
          />
          <TextField
            className="input-elem"
            id="outlined-textarea"
            label="Old Price"
            color="secondary"
            placeholder="Old Price..."
            name="oldprice"
            value={course.oldprice}
            type="number"
            InputProps={{
              inputProps: { min: 0, max: 1000 },
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={handleCourseChange}
          />

        </div>

      </div>}

      <div className="add-edit-course__input">
        <h3>Course description</h3>
        <p>This is a <a href="https://www.markdownguide.org/basic-syntax/" target='_blank'>markdown</a> editor. The preview will appear on the side.
          <span className="small-btn" onClick={fillMarkdownExample}>Fill it with an EXAMPLE</span></p>

        <div className="row">
          <TextField
            className="input-elem"
            id="outlined-multiline-flexible"
            label="Details"
            placeholder="Description..."
            multiline
            color="secondary"
            minRows={15}
            maxRows={100}
            name="description"
            value={course.description}
            onChange={handleCourseChange}
          />
          <div className="previewer input-elem">
            <ReactMarkdown children={course.description} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
      </div>
      {/* Todo 3: Type: video/article */}
      {/* Todo 4: Type === video => price, (oldprice), description (mar fent megvan) */}
      <div>
        <Button variant="contained" onClick={submitCourse}>{submitBtnText()}</Button>
      </div>

      <Snackbar open={successMessage != ''} autoHideDuration={5000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={errorMessage != ''} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {props.type === AddEditFormType.EDIT &&
        <>
          <h3>Videos</h3>

          <Button onClick={handleOpen}>Add Video</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <h3 className="modal__title">Add video</h3>
              <Box sx={modalContentStyle}>
                <div className="add-edit-course__input">
                  <TextField
                    className="input-elem"
                    id="outlined-textarea"
                    label="Title"
                    color="secondary"
                    placeholder="Title..."
                    name="title"
                    value={video.title}
                    onChange={handleVideoTitleChange}
                  />
                </div>
                <div className="add-edit-course__input">
                  <TextField
                    className="input-elem"
                    id="outlined-textarea"
                    label="Slug"
                    color="secondary"
                    placeholder="Slug..."
                    name="slug"
                    value={video.slug}
                    onChange={handleVideoSlugChange}
                  />
                </div>
                <div className="add-edit-course__input">
                  <input
                    accept="video/*"
                    className="input-elem photo-input"
                    ref={videoInputRef}
                    id="icon-button-photo"
                    name="video"
                    onChange={handleVideoFileChange}
                    type="file"
                  />
                </div>

                <FormControlLabel control={<Checkbox
                  checked={video.public}
                  sx={{
                    color: pink[800],
                    '&.Mui-checked': {
                      color: pink[600],
                    }
                  }} />}
                  label="Publicly accessible"
                  onChange={handleVideoPublicChange}
                />

                <div className="add-edit-course__input">
                  <h3>Description</h3>
                  <p>This is a <a href="https://www.markdownguide.org/basic-syntax/">markdown</a> editor. The preview will appear below.
                    {/* <span className="small-btn" onClick={fillMarkdownExample}>Fill it with an EXAMPLE</span> */}
                  </p>

                  <div className="row">
                    <TextField
                      className="input-elem"
                      id="outlined-multiline-flexible"
                      label="Details"
                      placeholder="Description..."
                      multiline
                      color="secondary"
                      minRows={15}
                      maxRows={100}
                      name="description"
                      value={video.description}
                      onChange={handleVideoChange}
                    />
                    <div className="previewer input-elem">
                      <ReactMarkdown
                        children={video.description} remarkPlugins={[remarkGfm]} />
                    </div>
                  </div>
                </div>
                {video.link !== '' && <InteractiveVideoEditor
                  video={video}
                  courseId={course.id}
                  interactiveCards={interactiveCards}
                  setInteractiveCards={setInteractiveCards}
                  setNewInteractiveCards={setNewInteractiveCards}
                />}
              </Box>

              <Button variant="contained" onClick={addNewVideo}>
                {video.id ? 'Update Video' : 'Add Video'}
              </Button>
            </Box>
          </Modal>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot): JSX.Element => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {videos.map((video, index) => (
                    <Draggable key={video.id} draggableId={`${video.id}`} index={index}>
                      {(provided: any, snapshot: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="video-list-element">
                            <div className="video-list-element-title" onClick={() => handleOpenId(video.id)}>
                              <div>{video.position + 1}. {video.title}</div>
                              <div className="video-list-element-progress">
                                {videoFiles && videoFiles[video.id] &&
                                  <LinearProgress variant="determinate" value={videoFiles[video.id].percent} />
                                }
                              </div>
                            </div>
                            {introVideo != parseInt(video.id) && <FontAwesomeIcon icon={solid('photo-video')} aria-hidden="true" onClick={() => handleSetIntroVideo(video.id)} />}
                            <FontAwesomeIcon icon={solid('trash')} aria-hidden="true" onClick={(event) => handleDeleteVideo(event, video.id)} />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>}
    </div >
  );
}

export default AddEditCourse;


