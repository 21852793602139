import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import './action-component.scss';
import CodeBlock from 'react-copy-code';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { ActionCard, ActionCardContentType } from '../../models/interactive-video/action-card.model';

export class ActionComponentProps {
  actionCard: ActionCard = new ActionCard({});
}

function ActionComponent
  (props: ActionComponentProps) {
  const [showContent, setShowContent] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const codeSnippet = `
  const a = 10, b = 30;
  const sum = a + b;
  console.log(sum);
  `;

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const toggleContent = () => {
    setShowContent(!showContent);
  }

  return (
    <div className="action-component" style={{backgroundColor: props.actionCard.cardColor}}>
      <span onClick={toggleContent} className="action-component__icon">+</span>
      {showContent && <div className="action-component__text-box action-component__text-box--left"  style={{backgroundColor: props.actionCard.cardColor}}>
        {props.actionCard.contentBlocks.map(block => {
          if (block.type === ActionCardContentType.TITLE) {
            return <h2>{block.content}</h2>
          } else if (block.type === ActionCardContentType.TEXT) {
            return <p>{block.content}</p>
          } else //if(block.type === ActionCardContentType.CODE)
          {
            {/* Code block */ }
            return (
              <div className="code-snippet">
                 <CopyToClipboard text={block.content} onCopy={onCopyText}>
                    <span>{isCopied ? "Copied!" : <MdContentCopy />}</span>
                  </CopyToClipboard>
                <div className="code-section">
                  <pre>{block.content}</pre>
                </div>
              </div>)
          }
        })}
        {/* <h2> Some title</h2>
        <p>Some content</p> */}
        {/* <CodeBlock highlight={true}>
      {"write the rest of your component here"}
      {"using as many pre code blocks you want"}
   </CodeBlock> */}

        {/* Code block */}
        {/* <div className="code-snippet">
          <div className="code-section">
            <pre>{codeSnippet}</pre>
            <CopyToClipboard text={codeSnippet} onCopy={onCopyText}>
              <span>{isCopied ? "Copied!" : <MdContentCopy />}</span>
            </CopyToClipboard>
          </div>
        </div>

        <p>Some content 2</p> */}
      </div>
      }
    </div>

  );
}

export default ActionComponent;
