import { ActionCardAlign } from "../../enums/actio-card-align.enum";
import { InteractiveCardType } from "../../enums/interactive-card-type";
import { InteractiveCard } from "../../interfaces/interactive-card.interface";
import { Question } from "./question-base.model";
import { QuestionForm, QuizzForm } from "./quizz-form.data";

export class QuestionCard implements InteractiveCard {
  // metadata
  id: number = 0;
  dx: number = 0;
  dy: number = 0;
  dxRel: number = 0;
  dyRel: number = 0;
  dxTemp: number = 0;
  dyTemp: number = 0;
  type: InteractiveCardType = InteractiveCardType.QUESTION;
  showTime: number = 0;
  hideTime: number = 0;
  pauseVideo: boolean = true;
  hasTimer: boolean = false;
  duration: number = 5; // this should be calculated based on each question's duration

  // content
  questions: Question[] = [];

  // style
  cardColor: string = 'black';

  constructor(data: any) {
    Object.assign(this, data);
    if(this.id === 0) {
      this.id = Math.floor(Math.random() * 1000000);
    }
  }

  toQuizzForm(): QuizzForm {
    const quizz: QuizzForm = new QuizzForm();
    quizz.questions = this.questions.map(question => new QuestionForm(question));
    return quizz;
  }
}

export { }